import React, { useEffect, useState } from "react";
import { createRent } from "../../store/rentReducer";
import { useDispatch, useSelector} from "react-redux";
import { authUser } from "../../store/authReducer";
import { getTariffs } from "../../store/tariffDictReducer"
import { authTracker, enableTracker, trackerSuccess, verificationError, verificationSuccess } from "../../store/trackerRecucer";
import { getScooters } from "../../store/scooterReducer";
import { loadUserInfo } from "../../store/userInfoReducer";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { smsAPI } from "../../api/api";

export const RentForm = () => {
  const dispatch = useDispatch();
  const scooterInfo = useSelector((state => state.scooterReducer));
  const trackerObj = useSelector((state) => state.trackerReducer);
  const adminObj = useSelector((state) => state.authReducer.user);
  const userAccessInfo = useSelector((state) => state.userInfoReducer);
  const tariffDict = useSelector((state) => state.tariffDictReducer);
  const [loading, setLoading] = useState(false);
  const userInfo = useSelector((state) => state.userInfoReducer);

  //Получаю залогинившего человека
  useEffect(() => {
    dispatch(authUser());
    dispatch(getTariffs());
    dispatch(getScooters());
    dispatch(loadUserInfo(userInfo.id));
  }, []);

  const connectTracker = () => {
    dispatch(authTracker());
  }

  let tariffArray = tariffDict.filter((tariff) => tariff.city.toLowerCase().includes(userAccessInfo.city.toLowerCase()));

  // записываю в переменную admin залогинившего человека
  const admin = adminObj.profile
    ? `${adminObj.profile.middle_name} ${adminObj.profile.first_name}`
    : "Админ";

  //Чтобы время было точное реализовал таймер
  const [time, setTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  let dateTime = time;
  let dateTimeString = time.toLocaleString("ru-RU");

  //получаю геопозицию
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setData({
        ...data,
        location: `${position.coords.latitude}, ${position.coords.longitude}`,
      });
    });
  }, []);

  //Логика выбора типа и  аренды и метода оплаты
  const useRadioButtons = (name, initialValue) => {
    const [value, setState] = useState(initialValue);
    const handleChange = (e) => {
      setState(e.target.value);
    };
    const inputProps = {
      name,
      type: "radio",
      onChange: handleChange,
    };
    return [value, inputProps];
  };

  const [payValue, payInputProps] = useRadioButtons("selectPay", "Нал");

  //состояние количества самокатов
  const [scooters, setScooters] = useState([]);

  //Состояние формы внесения аренды
  const [data, setData] = useState({
    admin: admin,
    user: "",
    phone: "",
    scooternumber: "",
    quantity: 0,
    pledge: "",
    date: dateTimeString,
    rentStart: dateTime,
    rentEnd: "",
    tariff: 0,
    price: 0,
    payMode: payValue,
    location: "",
    status: "В поездке",
    promoDiscount: false,
    city: userInfo.city,
  });

  //верификация номера по СМС
  const [code, setCode] = useState((Math.floor(Math.random() * 10000)).toString().padStart(4, '0'))
  const [inputCode, setInputCode] = useState("")
  const resetCode = () => setCode((Math.floor(Math.random() * 10000)).toString().padStart(4, '0'))

  const [verification, setVerification] = useState(false)
  const [verified, setVerified] = useState(false)
  const sendVerification = async () => {
    if (data.phone.length != 11) {
      dispatch(verificationError(`Проверьте правильность введенного номера телефона`))
      return
    }
    const res = await smsAPI.sendSMSCode(data.phone, code)
    if (res.data.status == "OK") {
      setVerification(true)
    } else {
      dispatch(verificationError(`Сообщение не отправлено. Код ошибки: ${res.data.status_code}`))
      resetCode()
    }
  }
  const verifyCode = async (input) => {
    if (input === code) {
      dispatch(verificationSuccess(`Номер подтвержден, выдача на прокат разрешена`))
      setVerification(false)
      setVerified(true)
      resetCode()
    } else {
      dispatch(verificationError(`Номер не подтвержден, проверьте введенные данные`))
    }
  }

  const [promocode, setPromocode] = useState("UR");

  //перезаписываются данные исходя из зависимостей

  useEffect(() => {
    setData({
      ...data,
      admin: admin,
      rentStart: dateTime,
      quantity: scooters.length,
      payMode: payValue,
    });
  }, [admin, dateTime, scooters, payValue]);

  const confirmPromo = () => {
    promocode === "UR2021" && (
      setData({...data, promoDiscount: true})
    );
  }

  //Отправка формы аренды
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    let scooterList = "";
    let trackerData = {
      admin: data.admin,
      fio: data.user,
      phone: data.phone,
    };
    for await (let scooter of scooters) {
      console.log(trackerData)
      let currentScooterInfo = scooterInfo.filter((e) => e.scooter.includes(scooter));
      if (currentScooterInfo[0] && currentScooterInfo[0].scooterTracker) {
      dispatch(enableTracker(scooter, trackerData));
      scooterList += (scooter + " ");
      }
    };
    dispatch(trackerSuccess(`Прокат записан, самокаты ${scooterList} включены`))
    dispatch(createRent(data));
    setData({
      admin: admin,
      user: "",
      phone: "",
      scooternumber: "",
      quantity: 0,
      pledge: "",
      date: dateTimeString,
      rentStart: dateTime,
      rentEnd: "",
      tariff: 0,
      price: data.price,
      payMode: payValue,
      location: "",
      status: "В поездке",
      promoDiscount: false,
      city: userInfo.city,
    });
    setPromocode("UR");
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    
    <>
      {(!userInfo.is_valid) && (
      <Redirect to="/instruction" />
      )}
      <div className="container my-4">
        <Link className="instruction-link font-weight-bold mt-2 mt-lg-0" to="/user">
          {admin
            ? Object.entries(admin).length !== 0
              ? admin
              : "Загрузка..."
            : "Войдите"}
        </Link>
        <h4 className="my-4">{`Текущее время: ${dateTimeString}`}</h4>
        {!localStorage.getItem("trackertoken") && (<button
        onClick = {connectTracker}
        className="btn btn-success  btn-lg btn-block"
        >
        Подключиться к трекерам  
        </button>)}
        <div className="row">
          <div className="col-12">
            <form onSubmit={submitHandler}>

              <div className="form-group">
                <label htmlFor="exampleInputUser">ФИО</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputUser"
                  placeholder="ФИО"
                  value={data.user}
                  name="user"
                  onChange={(e) => setData({ ...data, user: e.target.value })}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputPhone">Номер телефона</label>
                <input
                  type="tel"
                  className="form-control"
                  id="exampleInputScooter"
                  placeholder="Например: 89172239576"
                  value={data.phone}
                  name="phone"
                  pattern="(\+?\d[- .]*){11,12}"
                  onChange={(e) => setData({ ...data, phone: e.target.value })}
                  required
                />
              </div>

              <div className="form-group">
                {data.phone.length > 0 && (
                  verification ? (
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Код из СМС" onChange={(e) => setInputCode(e.target.value)}/>
                      <button className="btn btn-success" onClick={() => verifyCode(inputCode)}></button>
                    </div>
                  ) : (
                    <button className="btn btn-success btn-md btn-block" onClick={() => sendVerification()}>
                      Отправить код авторизации
                    </button>
                  )
                )}
              </div>

              <div className="form-group">
                <label htmlFor="inputTariffSelect">Тариф</label>
                <select
                  onChange={(e) => setData({...data, price:e.target.value})}
                  className="custom-select"
                  id="inputTariffSelect"
                >
                  <option selected disabled hidden value=""></option>
                  {tariffArray.map((tariff) => ( <option value={tariff.price}>{tariff.name}</option> ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputScooter">
                  Номер электросамоката
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputScooter"
                  placeholder="От 2 и более - номера записываются через пробел"
                  value={data.scooternumber}
                  name="scooternumber"
                  onChange={(e) => {
                    setData({ ...data, scooternumber: e.target.value });
                    setScooters(e.target.value.split(/[,. ]/).filter(Boolean));
                  }}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputScooter">Залог</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputScooter"
                  placeholder="Залог"
                  value={data.pledge}
                  name="pledge"
                  onChange={(e) => setData({ ...data, pledge: e.target.value })}
                />
              </div>

              <section className="mt-5">
                <div>
                  <input
                    id="control_06"
                    name="selectPay"
                    value="Нал"
                    {...payInputProps}
                    defaultChecked
                  />
                  <label className="cart-label" htmlFor="control_06">
                    Нал
                  </label>
                </div>
                <div>
                  <input
                    id="control_07"
                    name="selectPay"
                    value="Безнал"
                    {...payInputProps}
                  />
                  <label className="cart-label" htmlFor="control_07">
                    Безнал
                  </label>
                </div>
              </section>
              <div className="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center my-4">
                <label
                  htmlFor="socialDiscount"
                >
                  Использовать промокод
                </label>
                <input
                  type="text"
                  id="socialDiscount"
                  className="form-control"
                  value={promocode}
                  onChange={(e) => setPromocode(e.target.value)}
                  />
                <button
                  onClick={confirmPromo}
                  type="button"
                  className="btn btn-outline-success w-200 scooter-btn"
                >
                  Применить
                </button>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="mb-4 ">Количество самокатов: {data.quantity}</h4>
                <h4 className="mb-4 ">Цена проката: {Math.round(data.price * 60)} ₽/час.</h4>
              </div>
              <div>
                {data.promoDiscount ? (
                  <h6 className="mb-4 form-percents">
                    Скидка: <span>20% по окончанию поездки</span>
                  </h6>
                ) : data.quantity >= 4 && data.quantity < 10 ? (
                  <h6 className="mb-4 form-percents">
                    Скидка: <span>10% по окончанию поездки</span>
                  </h6>
                ) : data.quantity >= 10 && (
                  <h6 className="mb-4 form-percents">
                    Скидка: <span>15% по окончанию поездки</span>
                  </h6>
                )}
              </div>
              {/*<div className="d-flex justify-content-between align-items-center">
                <p className="document-warning">
                  Внимание! Для выдачи самокатов Kugoo M4, M4 Pro и V1 необходимо оформить акт приема-передачи самоката 
                </p>
                <a
                href={`${url}/static/Акт приема-передачи самоката.docx`}
                target="_blank"
                rel="noopener noreferrer"
                download
                className="btn btn-success btn-sm"
                >
                  Документ для печати
              </a>
              </div>*/}
              {verified && (
                <>
              {loading ? (
                <button
                  className="btn btn-success  btn-lg btn-block"
                  type="submit"
                  disabled
                >
                  Загрузка...
                </button>
              ) : data.city!=="" && (
                <button
                  type="submit"
                  className="btn btn-success  btn-lg btn-block"
                >
                  Начать прокат
                </button>
              )}
              </>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
