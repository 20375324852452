import { trackerApi } from "../api/api";

const AUTH_TRACKER = "AUTH_TRACKER";
const RESET_STATUS = "RESET_STATUS";
const TRACKER_ERROR = "TRACKER_ERROR";
const NO_TRACKER = "NO_TRACKER";
const FINISH = "FINISH";
const NOTIFY="NOTIFY";


const initialState = {
  user: {},
  notify: {},
  noTracker: false,
  trackerError: false,
  finished: false,
}; 

export const trackerReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_TRACKER:
      return {...state, trackerAuth: true, user: action.payload};
    case RESET_STATUS:
      return {...state, noTracker: false, trackerError: false};
    case TRACKER_ERROR:
      return {...state, trackerError: true}
    case NO_TRACKER:
      return {...state, noTracker: true}
    case FINISH:
      return {...state, finished: true}
    case NOTIFY:
      return {...state, notify: action.payload};
    default: return state;
  }
};

export const verificationError = (msg) => async(dispatch) => {
  dispatch({type: NOTIFY, payload: {error: msg}})
}

export const verificationSuccess = (msg) => async(dispatch) => {
  dispatch({type: NOTIFY, payload: {message: msg}})
}

export const authTracker = () => async(dispatch) => {
  try {
    const res = await trackerApi.authTracker();
    localStorage.setItem("trackertoken", res.data.access_token);
    dispatch({type: AUTH_TRACKER, payload: res.data});
  } catch (error) {
    console.log(error);
    dispatch({
      type: NOTIFY,
      payload: {
        error: "Не удалось подключиться к трекерам самокатов."
      }
    })
  }
};

export const trackerSuccess = (actionMessage) => async(dispatch) => {
  dispatch({type: NOTIFY, payload: {message: actionMessage}}) 
}

export const enableTracker = (scooter, data) => async(dispatch) => {
  try {
    let token = localStorage.getItem("trackertoken"); 
    const res = await trackerApi.trackerOn(token, scooter, data);
    if (!res.data.result) {
      if (res.data.error.includes("не найден"))
        dispatch({type: NOTIFY, payload: {error: res.data.error}});
      else {
        dispatch({type: NOTIFY, payload: {error: res.data.error}});
      }
    }
  } catch (error) {
    dispatch({type: NOTIFY, payload: {error: error.message}})
  }
};

export const disableTracker = (scooter, data) => async(dispatch) => {
  try {
    let token = localStorage.getItem("trackertoken");
    const res = await trackerApi.trackerOff(token, scooter, data);
    if (!res.data.result) {
      if (res.data.error.includes("не найден"))
        dispatch({type: NOTIFY, payload: {error: res.data.error}})
      else {
        dispatch({type: NOTIFY, payload: {error: res.data.error}})
      }
    }
  } catch (error) {
    dispatch({type: NOTIFY, payload: {error: error.message}});
  }
}