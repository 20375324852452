import { tariffDictApi } from "../api/api";

const GET_TARIFFS = "GET_TARIFFS";

export const tariffDictReducer = (state = [], action) => {
  switch(action.type) {
    case GET_TARIFFS:
      return action.payload;
    default: return state;
  }
}

export const getTariffs = () => async (dispatch) => {
  try {
    const {data} = await tariffDictApi.getTariffs();
    dispatch({type: GET_TARIFFS, payload: data});
  } catch(error) {
    console.log(error.message);
  }
};