import { placeDictApi } from "../api/api";

const GET_PLACE_DICT = "GET_PLACE_DICT";

export const placeDictReducer = (state = [], action) => {
  switch(action.type) {
    case GET_PLACE_DICT:
      return action.payload;
    default: return state;
  }
}

export const getPlaceDict = () => async (dispatch) => {
  try {
    const {data} = await placeDictApi.getPlaceDict();
    dispatch({type: GET_PLACE_DICT, payload: data});
  } catch(error) {
    console.log(error.message);
  }
};