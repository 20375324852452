import { penaltyAPI } from "../api/api";

const GET_PENALTY = "GET_PENALTY";
const POST_PENALTY = "POST_PENALTY";
const UPDATE_PENALTY = "UPDATE_PENALTY";
const DELETE_PENALTY = "DELETE_PENALTY";

export const penaltiesReducer = (state = [], action) => {
  switch (action.type) {
    case GET_PENALTY:
      return action.payload;
    case POST_PENALTY:
      return [...state, action.payload];
    case UPDATE_PENALTY:
      return state.map((penalty) =>
        penalty._id === action.payload._id ? action.payload : penalty
      );
    case DELETE_PENALTY:
      return state.filter((penalty) => penalty._id !== action.payload)
    default:
      return state;
  }
};

export const getPenalties = () => async (dispatch) => {
  try {
    const {data} = await penaltyAPI.getPenalties();
    dispatch({type: GET_PENALTY, payload: data});
  } catch (error) {
    console.log(error.message);
  }
};

export const createPenalty = (penalty) => async (dispatch) => {
  try {
    const {data} = await penaltyAPI.newPenalty(penalty);
    dispatch({type: POST_PENALTY, payload: data});
  } catch (error) {
    console.log(error.message);
  }
};

export const updatePenalty = (id, penalty) => async (dispatch) => {
  try {
    const {data} = await penaltyAPI.updatePenalty(id, penalty);
    dispatch({type: UPDATE_PENALTY, payload: data});
  } catch(error) {
    console.log(error.message);
  }
}

export const deletePenalty = (id) => async (dispatch) => {
  try {
    await penaltyAPI.deletePenalty(id);
    dispatch({type: DELETE_PENALTY, payload: id});
  } catch(error) {
    console.log(error.message);
  }
};