import React, {useEffect, useState} from "react";
import { loadUserInfo, updateUser } from "../../store/userInfoReducer";
import { useDispatch, useSelector } from "react-redux";
import { getPlaceDict } from "../../store/placeDictReducer";
import { Redirect } from "react-router-dom";

export const User = () => {
  const dispatch = useDispatch()
  const placeDict = useSelector((state) => state.placeDictReducer);
  const userInfo = useSelector((state) => state.userInfoReducer);
  const [redirectHandler, redirect] = useState(false);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    dispatch(getPlaceDict());
  }, [])
  useEffect(() => {
    dispatch(loadUserInfo(userInfo.userid));
  }, [placeDict])

  const [data, setData] = useState({
    userid: userInfo.userid,
    username: userInfo.username,
    fullname: userInfo.fullname,
    city: userInfo.city,
    is_valid: userInfo.is_valid,
    is_admin: userInfo.is_admin,
    is_super: userInfo.is_super,
    is_active: userInfo.is_active,
    pass_scan_main: userInfo.pass_scan_main,
    pass_scan_reg: userInfo.pass_scan_reg,
    pass_scan_family: userInfo.pass_scan_family,
    doc_scan_agreement: userInfo.doc_scan_agreement,
    doc_scan_instruction: userInfo.doc_scan_instruction,
    doc_scan_resp: userInfo.doc_scan_instruction,
    doc_scan_consent: userInfo.doc_scan_consent
  })

  const uploadPhoto = (tag, image) => {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", "scooter-cloud");
    formData.append("cloud_name", "radius");
    fetch("https://api.cloudinary.com/v1_1/radius/image/upload", {
      method: "post",
      body: formData,
    })
      .then((resp) => resp.json())
      .then((resData) => { switch (tag) {
        case "pass_scan_main": setData({ ...data, pass_scan_main: resData.url}); break;
        case "pass_scan_reg": setData({ ...data, pass_scan_reg: resData.url}); break;
        case "pass_scan_family": setData({ ...data, pass_scan_family: resData.url}); break;
        case "doc_scan_agreement": setData({ ...data, doc_scan_agreement: resData.url}); break;
        case "doc_scan_instruction": setData({ ...data, doc_scan_instruction: resData.url}); break;
        case "doc_scan_resp": setData({ ...data, doc_scan_resp: resData.url}); break;
        case "doc_scan_consent": setData({ ...data, doc_scan_consent: resData.url}); break;
      }
      })
      .catch((err) => console.log(err));
  }

  let cityArray = placeDict.filter(
    (thing, index, self) => index === self.findIndex((t) => t.city === thing.city)
  );
  let cityNames = cityArray.map((item) => item.city);
  useEffect(() => {
    setCityList(cityNames);
  }, [placeDict]);

  const sumbitUpdate = () => {
    dispatch(updateUser(data));
    redirect(true);
  }

  return (
    <>
      {redirectHandler && (<Redirect to="/" />)}
      <div className="container my-4">
        <h4 className="my-4 font-weight-bold">
          {data.fullname}
        </h4>
        <p>{data.username}</p>
        <p>ID пользователя: {userInfo.userid}</p>
        {!userInfo.is_valid && (
        <p className="text-danger warning">
          Внимание! Доступ ограничен до согласования доступа к работе. Загрузите сканы паспорта, договоров с подписью и выберите город для оформления заявки на доступ.
        </p>
        )}
        <label htmlFor="InputCitySelect">Город</label>
        <select
          onChange={(e) => setData({...data, city:e.target.value})}
          className="custom-select"
          id="inputCitySelect"
          >
            <option value="" selected disabled hidden>Выберите город</option>
            {cityList.map((city) => ( <option value={city}>нефтекамск</option> ))}
        </select>
        <h4 className="my-3 font-weight-bold">
          Документы
        </h4>
        <div className="row my-4">
          <div className="col-12 col-lg-6 my-1 input-group">
          {data.pass_scan_main && (
            <img
            className="docs-img"
            src={data.pass_scan_main}
            />
          )}
          <div className="custom-file">
            <input id="passMain"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              className="custom-file-input"
              onChange={(e) => {uploadPhoto("pass_scan_main", e.target.files[0])}}
            />
            <label className="custom-file-label" htmlFor="passMain">
              Паспорт: Главная страница {data.pass_scan_main && "(Загружено)"}
            </label>
            </div>
          </div>

          <div className="col-12 col-lg-6 my-1 input-group">
          {data.pass_scan_reg && (
            <img
            className="docs-img"
            src={data.pass_scan_reg}
            />
          )}
          <div className="custom-file">
            <input id="passMain"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              className="custom-file-input"
              onChange={(e) => {uploadPhoto("pass_scan_reg", e.target.files[0])}}
            />
            <label className="custom-file-label" htmlFor="passMain">
              Паспорт: Страница прописки {data.pass_scan_reg && "(Загружено)"}
            </label>
            </div>
          </div>

          <div className="col-12 col-lg-6 my-1 input-group">
          {data.pass_scan_family && (
            <img
            className="docs-img"
            src={data.pass_scan_family}
            />
          )}
          <div className="custom-file">
            <input id="passMain"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              className="custom-file-input"
              onChange={(e) => {uploadPhoto("pass_scan_family", e.target.files[0])}}
            />
            <label className="custom-file-label" htmlFor="passMain">
              Паспорт: Страница "Семейное положение" {data.pass_scan_family && "(Загружено)"}
            </label>
            </div>
          </div>

          <div className="col-12 col-lg-6 my-1 input-group">
          {data.doc_scan_agreement && (
            <img
            className="docs-img"
            src={data.doc_scan_agreement}
            />
          )}
          <div className="custom-file">
            <input id="passMain"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              className="custom-file-input"
              onChange={(e) => {uploadPhoto("doc_scan_agreement", e.target.files[0])}}
            />
            <label className="custom-file-label" htmlFor="passMain">
              Скан трудового договора с подписью {data.doc_scan_agreement && "(Загружено)"}
            </label>
            </div>
          </div>

          <div className="col-12 col-lg-6 my-1 input-group">
          {data.doc_scan_instruction && (
            <img
            className="docs-img"
            src={data.doc_scan_instruction}
            />
          )}
          <div className="custom-file">
            <input id="passMain"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              className="custom-file-input"
              onChange={(e) => {uploadPhoto("doc_scan_instruction", e.target.files[0])}}
            />
            <label className="custom-file-label" htmlFor="passMain">
              Скан должностной инструкции с подписью {data.doc_scan_instruction && "(Загружено)"}
            </label>
            </div>
          </div>

          <div className="col-12 col-lg-6 my-1 input-group">
          {data.doc_scan_resp && (
            <img
            className="docs-img"
            src={data.doc_scan_resp}
            />
          )}
          <div className="custom-file">
            <input id="passMain"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              className="custom-file-input"
              onChange={(e) => {uploadPhoto("doc_scan_resp", e.target.files[0])}}
            />
            <label className="custom-file-label" htmlFor="passMain">
              Скан договора об ответственности с подписью {data.doc_scan_resp && "(Загружено)"}
            </label>
            </div>
          </div>

          <div className="col-12 col-lg-6 my-1 input-group">
          {data.doc_scan_consent && (
            <img
            className="docs-img"
            src={data.doc_scan_consent}
            />
          )}
          <div className="custom-file">
            <input id="passMain"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              className="custom-file-input"
              onChange={(e) => {uploadPhoto("doc_scan_consent", e.target.files[0])}}
            />
            <label className="custom-file-label" htmlFor="passMain">
              Скан согласия родителей с подписями (Необязательно) {data.doc_scan_consent && "(Загружено)"}
            </label>
            </div>
          </div>
        </div>
        {(data.city==="" || !data.pass_scan_main || !data.pass_scan_reg || !data.pass_scan_family || !data.doc_scan_agreement || !data.doc_scan_instruction || !data.doc_scan_resp) ? (
          <button
          className="btn btn-success  btn-lg btn-block"
          disabled
          >
            Сохранить
          </button>
          ) : (
          <button
          className="btn btn-success  btn-lg btn-block"
          onClick={sumbitUpdate}
          >
            Сохранить
          </button>
              )}
      </div>
    </>
  )
}