import React, {useEffect, useState} from "react";
import { createRepair } from "../../store/repairReducer";
import { useDispatch, useSelector } from "react-redux";
import { authUser } from "../../store/authReducer";
import { Link } from "react-router-dom";

export const RepairForm = () => {
  const dispatch = useDispatch();
  const adminObj = useSelector((state) => state.authReducer.user);
  
  const [time, setTime] = useState(new Date());
  const [loading, setLoading] = useState(false);

  //Таймер для точного времени
  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => {
      clearInterval(interval);
    }
  }, []);

  //Статусы по умолчанию при создании новой заявки
  const defaultStatus = "Ожидание";
  const defaultPayMode = "Не опл.";

  //информация о логине сотрудника с записью в admin
  useEffect(() => {
    dispatch(authUser());
  }, []);
  const admin = adminObj.profile
    ? `${adminObj.profile.middle_name} ${adminObj.profile.first_name}`
    : "Админ";
  
  //геопозиция
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function(position) {
      setData({
        ...data,
        location: `${position.coords.latitude}, ${position.coords.longitude}`,
      });
    });
  }, []);

  //Состояние формы внесения аренды
  const [data, setData] = useState({
    admin: admin,
    client: "",
    phone: "",
    product: "",
    malfunction: "",
    date: time,
    status: defaultStatus,
    sum: "",
    payMode: defaultPayMode,
    location: "",
  });

  //перезаписываются данные исходя из зависимостей
  useEffect(() => {
    setData({
      ...data,
      admin: admin,
      time: time,
    });
  }, [admin, time]);

  //Отправка формы ремонта
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(createRepair(data));

    setData({
      admin: admin,
      client: "",
      phone: "",
      product: "",
      malfunction: "",
      date: time,
      status: defaultStatus,
      sum: "",
      payMode: defaultPayMode,
     location: "",
    });
    setTimeout(() =>{
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      <div className="container my-4">
      <div className="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center my-4">
        <h4 className="my-4 font-weight-bold">
          {admin
            ? Object.entries(admin).length !==0
              ? admin
              : "Загрузка..."
            : "Войдите"}
        </h4>
        <Link
        className="instruction-link font-weight-bold mt-2 mt-lg-0"
        to="/repairlist"
        >
          Заявки на ремонт
        </Link>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={submitHandler}>
              <div className="form-group">
                <label htmlFor="exampleInputClient">ФИО</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputClient"
                  placeholder="ФИО"
                  value={data.client}
                  name="client"
                  onChange={(e) => setData({...data, client: e.target.value})}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputPhone">Номер телефона</label>
                <input
                  type="tel"
                  className="form-control"
                  id="exampleInputPhone"
                  placeholder="Например: 89172239576"
                  value={data.phone}
                  name="phone"
                  pattern="(\+?\d[- .]*){11,12}"
                  onChange={(e) => setData({...data, phone:e.target.value})}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputProduct">Объект ремонта</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputProduct"
                  placeholder="Велосипед/Самокат/и т.д."
                  value={data.product}
                  name="product"
                  onChange={(e) => 
                    setData({ ...data, product: e.target.value })
                  }
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputMalf">Неисправность</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputMalf"
                  placeholder="Краткое описание"
                  value={data.malfunction}
                  name="malfunction"
                  onChange={(e) =>
                    setData({ ...data, malfunction: e.target.value })
                  }
                  required
                />
              </div>

              {loading ? (
                <button
                  className="btn btn-success btn-lg btn-block"
                  type="submit"
                  disabled
                >
                  Загрузка...
                </button>
              ) : (
                <button
                  className="btn btn-success btn-lg btn-block"
                  type="submit"
                >
                  Оформить ремонт
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};