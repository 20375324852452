import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { getPenalties, createPenalty, updatePenalty, deletePenalty } from "../../store/penaltiesReducer";
import { useDispatch, useSelector } from "react-redux";
import preloader from "../Place/preloader.gif";
import { PenaltyItem } from "./PenaltyItem";

export const Penalties = () => {
  const dispatch = useDispatch();
  const penalties = useSelector((state) => state.penaltiesReducer);
  const places = useSelector((state) => state.placeReducer);
  const username = useSelector((state) => state.userInfoReducer.fullname);
  const [image, setImage] = useState({});
  const [loading, setLoading] = useState(false);
  const [imageLoad, setImageLoad] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [search, setSearch] = useState("")
  const [filteredPenalties, setFilter] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    dispatch(getPenalties());
  }, [])
  let adminArray = places.filter(
    (thing, index, self) => 
      index === self.findIndex((t) => t.admin === thing.admin)
  );
  let adminNames = adminArray.map((item) => item.admin);
  useEffect(() => {
    setAdmins(adminNames)
  }, [penalties])

  useEffect(() => {
    setFilter(penalties.filter((penalty) => penalty.subject.includes(search)))
  }, [search, penalties])

  const [data, setData] = useState({
    admin: username,
    subject: "",
    timestamp: "",
    description: "",
    image: "",
    sum: 0
  })

  const itemsPerPage = 6;
  const pagesVisited = pageNumber * itemsPerPage;
  const pageCount = Math.ceil(filteredPenalties.length / itemsPerPage)

  const changePage = ({selected}) => {
    setPageNumber(selected);
  }

  const uploadImage = () => {
    setImageLoad(true);
    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", "scooter-cloud");
    formData.append("cloud_name", "radius");
    fetch("https://api.cloudinary.com/v1_1/radius/image/upload", {
      method: "post",
      body: formData,
    })
      .then((resp) => resp.json())
      .then((resData) => {
        setData({ ...data, image: resData.url });
      })
      .then(() => setImageLoad(false))
      .then(() => setImageLoaded(true))
      .catch((err) => console.log(err));
  };

  const submitHandler = async(e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(createPenalty(data));
    
    setData({
      admin: username,
      subject: data.subject,
      timestamp: "",
      description: "",
      image: "",
      sum: 0
    })
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      <div className="container my-4">
        <div className = "d-flex align-items-center">
          <h4 className="mr-2 m-0">Новое нарушение</h4>
        </div>
      <form onSubmit={submitHandler}>
        <div>
          <div className="form-group">
            <label htmlFor="inputSubject">Сотрудник</label>
            <select
              className="custom-select"
              id="inputSubject"
              onChange={(e) => setData({...data, subject: e.target.value})}
            >
              <option selected disabled hidden value=""></option>
              {admins.map((admin) => (<option value={admin}>{admin}</option>))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="inputDescription">Нарушение</label>
            <input
              type="text"
              className="form-control"
              placeholder="Краткое описание нарушения"
              value={data.description}
              name="description"
              onChange={(e) => setData({...data, description: e.target.value})}
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputSum">Размер штрафа</label>
            <input
              type="number"
              className="form-control"
              value={data.sum}
              name="sum"
              onChange={(e) => setData({...data, sum: e.target.value})}
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputTime">Дата и время нарушения</label>
            <input
              type="datetime-local"
              className="form-control"
              value={data.timestamp}
              name="timestamp"
              onChange={(e) => setData({...data, timestamp: e.target.value})}
            />
          </div>
          <div className="input-group">
            <div className="custom-file">
              <input
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                onChange={(e) => setImage(e.target.files[0])}
                className="custom-file-input"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
              />
            <label
              className="custom-file-label"
              htmlFor="inputGroupFile04"
            >
              {image.name ? image.name : "Выбрать фото"}
            </label>
            </div>
            <div className="input-group-append">
              {image.name ? (
                <button
                  className="btn btn-outline-success"
                  type="button"
                  id="inputGroupFileAddon04"
                  onClick={uploadImage}
                >
                  Загрузить
                </button>
              ) : (
                <button
                  className="btn btn-outline-success"
                  type="button"
                  id="inputGroupFileAddon04"
                  disabled
                >
                  Загрузить
                </button>
              )}
            </div>
          </div>
          <div className="my-2">
            <p className="font-weight-bold">
              Загрузите фотографию зафиксированного нарушения
            </p>
            <div className="d-flex justify-content-center my-2">
              {imageLoad && <img src={preloader} alt="preloader" />}
              {data.image && (
                <img
                  className="place-img"
                  src={data.image}
                  alt={data.image}
                />
              )}
            </div>
          </div>
          {loading ? (
            <button
              className="btn btn-success btn-lg btn-block"
              type="submit"
              disabled
            >
              Загрузка...
            </button>
          ) : (!data.image) ? (
            <button
              className="btn btn-success btn-lg btn-block"
              type="submit"
              disabled
            >
              Фото не загружено
            </button>
          ) : (
            <button
              className="btn btn-success btn-lg btn-block"
              type="submit"
            >
              Создать запись
            </button>
          )}
        </div>
      </form>
    </div>

    <div className="container my-4">
      <h4 className="mr-2 m-0">Штрафы</h4>
      <div>
        <label htmlFor="searchSubject">Сотрудник</label>
        <select
          className="custom-select"
          id="searchSubject"
          onChange={(e) => setSearch(e.target.value)}
        >
          <option selected disabled hidden value=""></option>
          {admins.map((admin) => (<option value={admin}>{admin}</option>))}
        </select>
      </div>
        <div className="my-3 table-responsive">
        <table
          className="table-bordered table-hover w-100 text-uppercase"
          stype={{minWidth: "600px", cursor: "pointer"}}
        >
          <thead>
            <tr>
              <td className="p-2">Ответственный</td>
              <td className="p-2">Сотрудник</td>
              <td className="p-2">Нарушение</td>
              <td className="p-2">Дата</td>
              <td className="p-2">Фотография</td>
              <td className="p-2">Штраф</td>
              <td className="p-2">Действия</td>
            </tr>
          </thead>
          <tbody>
            {filteredPenalties.length > 0 ? (
              filteredPenalties
                .slice(pagesVisited, pagesVisited + itemsPerPage)
                .map((penalty) => (
                  <tr key={penalty._id}>
                    <PenaltyItem penalty={penalty} dispatch={dispatch} updatePenalty={updatePenalty} deletePenalty={deletePenalty}/>
                  </tr>
                ))
            ) : (
              <tr>
                <td className="p-2">
                <p className="my-4">Загрузка...</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        </div>
        <div className="mt-4 d-flex justify-content-center align-items-center">
          <ReactPaginate
            marginPagesDisplayed={2}
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
    </div>
    </>
  );
}