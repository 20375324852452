import { placeApi } from "../api/api";

const GET_PLACE = "GET_PLACE";
const POST_PLACE = "POST_PLACE";
const UPDATE_PLACE = "UPDATE_PLACE";
const DELETE_PLACE = "DELETE_PLACE";

//reducer открытия точки

export const placeReducer = (state = [], action) => {
  switch (action.type) {
    case GET_PLACE:
      return action.payload;
    case POST_PLACE:
      return [...state, action.payload];
    case UPDATE_PLACE:
      return state.map((place) =>
        place._id === action.payload._id ? action.payload : place
      );
    case DELETE_PLACE:
      return state.filter((place) => place._id !== action.payload);
    default:
      return state;
  }
};

//action получения точки

export const getPlace = () => async (dispatch) => {
  try {
    const { data } = await placeApi.getPlace();
    dispatch({ type: GET_PLACE, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

//action создания точки

export const createPlace = (place) => async (dispatch) => {
  try {
    const { data } = await placeApi.createPlace(place);
    dispatch({ type: POST_PLACE, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

//action изменения точки в анализе

export const updatePlace = (id, place) => async (dispatch) => {
  try {
    const { data } = await placeApi.updatePlace(id, place);
    dispatch({type: UPDATE_PLACE, payload: data});
  } catch(error) {
    console.log(error.message);
  }
}

//action удаления смены из анализа

export const deletePlace = (id) => async (dispatch) => {
  try {
    await placeApi.deletePlace(id);
    dispatch({type: DELETE_PLACE, payload: id});
  } catch(error) {
    console.log(error.message);
  }
};
