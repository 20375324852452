import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { getScooters } from "../../store/scooterReducer";

export const ScooterItem = ({
  _id,
  image,
  scooter,
  model,
  scooterError,
  scooterTrip,
  scooterPlace,
  scooterNote,
  scooterSpares,
  scooterWorks,
  updateScooter,
}) => {
  const dispatch = useDispatch();
  //состояние переключения изменения редактирования таблицы электросамоката
  const [scooterToggle, setScooterToggle] = useState(false);
  //состояние формы изменения самоката
  const [data, setdata] = useState({
    error: scooterError,
    trip: scooterTrip,
    place: scooterPlace,
    note: scooterNote,
    spares: scooterSpares,
    works: scooterWorks,
  });

  useEffect(() => {
    setdata(data);
  }, [data]);

  const onActivateEdit = () => {
    setScooterToggle(true);
  };

  const onDeactivateEdit = (id) => {
    dispatch(updateScooter(id, data));
    setScooterToggle(false);
  };

  return (
    <>
      <td className="p-2">
        <img className="scooter-img" src={image} alt={scooter} />
      </td>
      <td className="p-2">{scooter}</td>
      <td className="p-2">{model}</td>
      <td className="p-2">
        {scooterToggle ? (
          <>
            <BootstrapSwitchButton
              checked={data.error}
              onChange={(checked) => {
                setdata({ ...data, error: checked });
              }}
              onlabel="Нерабочий"
              offlabel="Рабочий"
              style="w-100"
              onstyle="success"
            />
          </>
        ) : data.error ? (
          "Нерабочий"
        ) : (
          "Рабочий"
        )}
      </td>
      <td className="p-2">
        {scooterToggle ? (
          <input
            autoFocus={true}
            value={data.place}
            onChange={(e) => setdata({ ...data, place: e.target.value })}
            type="text"
          />
        ) : (
          data.place
        )}
      </td>
      <td className="p-2">
        {scooterToggle ? (
          <>
            <BootstrapSwitchButton
              checked={data.trip}
              onChange={(checked) => {
                setdata({ ...data, trip: checked });
              }}
              onlabel="В аренде"
              offlabel="На зарядке"
              style="w-100"
              onstyle="success"
            />
          </>
        ) : data.trip ? (
          "В аренде"
        ) : (
          "На зарядке"
        )}
      </td>
      <td className="p-2">
        {scooterToggle ? (
          <input
            autoFocus={true}
            value={data.note}
            onChange={(e) => setdata({ ...data, note: e.target.value })}
            type="text"
          />
        ) : (
          data.note
        )}
      </td>
      <td className="p-2">
        {scooterToggle ? (
          <input
            autoFocus={true}
            value={data.spares}
            onChange={(e) => setdata({ ...data, spares: e.target.value })}
            type="text"
          />
        ) : (
          data.spares
        )}
      </td>
      <td className="p-2">
        {scooterToggle ? (
          <input
            autoFocus={true}
            value={data.works}
            onChange={(e) => setdata({ ...data, works: e.target.value })}
            type="text"
          />
        ) : (
          data.works
        )}
      </td>
      <td className="p-2">
        {scooterToggle ? (
          <button
            onClick={() => onDeactivateEdit(_id)}
            className="btn btn-success"
          >
            Ок
          </button>
        ) : (
          <button
            onClick={onActivateEdit}
            className="btn btn-outline-success scooter-btn"
          >
            Изменить
          </button>
        )}
      </td>
    </>
  );
};
