import axios from "axios";

//url по которой проходят запросы на сервер

// основной сервер Heroku arcane-lake-14121
//"https://arcane-lake-14121.herokuapp.com/"  "https://scooterback.adsword.ru
//тестовый локальный сервер
//http://localhost:5000 http://192.168.1.35:5602

//Основной url. Можно изменить на другой, к примеру тестовый

export const url = "https://scooterback.adsword.ru";

const instance = axios.create({
  baseURL: url,
});

//url телемеда
const telemedUrl = "https://telemed-radius.ddns.net";

//url Трекеров
//https://uram.ddns.net (тестовая http://192.168.1.41:5000)
const uramUrl = "https://uram.ddns.net";

const sms_id = "7C737461-7995-AC3F-0EEA-827F27C51287"
export const smsAPI = {
  getCallCode(phone) {
    return axios.get(`https://sms.ru/code/call?phone=${phone}&ip=-1&api_id=${sms_id}`)
  },
  sendSMSCode(phone, code) {
    let msg = `Ваш код проката: ${code}. URAM.`
    return axios.get(`https://sms.ru/sms/send?api_id=${sms_id}&to=${phone}&msg=${msg}&json=1&test=1`)
  }
}

//апи аренды
export const rentAPI = {
  //получить аренды
  getRent() {
    return instance.get(`/rents`);
  },
  //созадать аренду
  createRent(data) {
    return instance.post(`/rents`, { data });
  },
  //изменить аренду
  updateRent(id, data) {
    return instance.patch(`/rents/${id}`, { data });
  },
  //удалить аренду
  deleteRent(id) {
    return instance.delete(`/rents/${id}`);
  },
};

//api ремонтов

export const repairAPI = {
  //список ремонтов
  getRepair() {
    return instance.get(`/repair`);
  },
  //новый ремонт
  createRepair(data) {
    return instance.post(`/repair`, {data});
  },
  //изменить ремонт
  updateRepair(id, data) {
    return instance.patch(`/repair/${id}`, {data});
  },
  //удалить ремонт
  deleteRepair(id) {
    return instance.delete(`/repair/${id}`);
  }
}

export const scooterRepairApi = {
  getScooterRepair() {
    return instance.get(`/scooterrepairs`)
  },
  createScooterRepair(data) {
    return instance.post(`/scooterrepairs`, {data});
  },
  updateScooterRepair(id, data) {
    return instance.patch(`/scooterrepairs/${id}`, data);
  }
}

export const partsApi = {
  getParts() {
    return instance.get(`/parts`);
  }
}

//апи электросамокатов
export const scooterApi = {
  //получить электросамокаты
  getScooter() {
    return instance.get(`/scooters`);
  },
  //изменить электросамокаты
  updateScooter(id, data) {
    return instance.patch(`/scooters/${id}`, { data });
  },
};

//апи открытия точки

export const placeApi = {
  //получить точки
  getPlace() {
    return instance.get(`/place`);
  },
  //создать открытие точки
  createPlace(data) {
    return instance.post(`/place`, { data });
  },
  //изменить данные о смене точки
  updatePlace(id, data) {
    return instance.patch(`/place/${id}`, { data });
  },
  //удалить данные о точке
  deletePlace(id) {
    return instance.delete(`/place/${id}`);
  },
};

export const analysisApi = {
  createAnalysis() {
    return instance.get(`/analysis`);
  },
};

export const placeDictApi = {
  getPlaceDict() {
    return instance.get(`/placedict`)
  },
};

export const tariffDictApi = {
  getTariffs() {
    return instance.get(`/tariffs`)
  },
}

export const authApi = {
  authUser(token) {
    return axios.get(`${telemedUrl}/api/auth/me/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  registerUser(data) {
    return axios.post(`${telemedUrl}/api/auth/register/`, data);
  },
  loginUser(data) {
    return axios.post(`${telemedUrl}/oauth/token/`, data);
  },
};

export const userAPI = {
  getUser(userid) {
    return instance.get(`/user?userid=${userid}`);
  },
  newUser(data) {
    return instance.post(`/user`, {data});
  },
  updateUser(data) {
    return instance.patch(`/user/`, {data});
  },
  getEmployees() {
    return instance.get(`/user/list`)
  }
}

export const imageUpload = async (images) => {
  let imgArr = [];
  for (const item of images) {
    const formData = new FormData();
    formData.append("file", item);
    formData.append("upload_preset", "scooter-cloud");
    formData.append("cloud_name", "radius");

    /* await axios("https://api.cloudinary.com/v1_1/radius/image/upload", {
      method: "POST",
      body: formData,
    }); */
  }
  return imgArr;
};

export const trackerApi = {
  authTracker() {
    return axios.post(`${uramUrl}/login`, {"login": "ilya", "password": "1679430258"});
  },
  trackerOn(token, scooter, data) {
    return axios.post(`${uramUrl}/scooter/${scooter}/on`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      }
      );
  },
  trackerOff(token, scooter, data) {
    return axios.post(`${uramUrl}/scooter/${scooter}/off`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
  }
}

export const penaltyAPI = {
  getPenalties() {
    return instance.get(`/penalties`)
  },
  newPenalty(data) {
    return instance.post(`/penalties`, {data})
  },
  updatePenalty(id, data) {
    return instance.patch(`/penalties/${id}`, {data})
  },
  deletePenalty(id) {
    return instance.delete(`/penalties/${id}`)
  }
}