import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//в переменную root записываем админов, кто может удалять смены

export const AnalysisPlaces = ({
  place,
  dispatch,
  deletePlace,
  updatePlace,
  access
}) => {
  const [data, setData] = useState({
    admin: place.admin,
    city: place.city,
    place: place.place,
    beginDate: place.beginDate,
    beginTime: place.beginTime,
    endTime: place.endTime,
    propertyScooters: place.propertyScooters,
    propertyKeys: place.propertyKeys,
    propertyCamera: place.propertyCamera,
    propertyTent: place.propertyTent,
    urlImage: place.urlImage,
    location: place.location,
  });

  //переключение состояния редактирования
  const [editToggle, setEditToggle] = useState(false);


  const onActivateEdit = () => {
    setEditToggle(true);
  };

  const onDeactivateEdit = () => {
    dispatch(updatePlace(place._id, data));
    setEditToggle(false);
  };

  return (
    <>
      {editToggle ? (
        <>
          <td className="p-2">
            {place.urlImage ? (
              <img
                className="analysis-img"
                src={place.urlImage}
                alt={place.place}
              />
            ) : (
              "Изображения нет"
            )}
          </td>
          <td className="p-2">{place.admin}</td>
          <td className="p-2">{place.beginDate}</td>
          <td className="p-2">
            <select
              onChange={(e) => setData({ ...data, place: e.target.value })}
              className="custom-select"
              value={data.place}
            >
              <option value="Гараж">Гараж</option>
              <option value="Лес">Лес</option>
              <option value="ТЦ">ТЦ</option>
            </select>
          </td>
          <td className="p-2">
            <input
              autoFocus={true}
              onChange={(e) => {
                setData({ ...data, beginTime: e.target.value });
              }}
              value={data.beginTime}
              type="time"
              required
            />
          </td>
          <td className="p-2">
            <input
              autoFocus={true}
              onChange={(e) => {
                setData({ ...data, endTime: e.target.value });
              }}
              value={data.endTime}
              type="time"
              required
            />
          </td>
          <td className="p-2">
            {place.propertyScooters && "Самокаты"}{" "}
            {place.propertyKeys && "Ключи"} {place.propertyCamera && "Камеры"}{" "}
            {place.propertyTent && "Палатка"}
          </td>
          <td className="p-2">
            {place.location ? place.location : "Локации нет"}
          </td>
          <td className="p-2">
            <button
              onClick={onDeactivateEdit}
              type="button"
              className="btn btn-outline-success w-100 scooter-btn"
            >
              Готово
            </button>
          </td>
        </>
      ) : (
        <>
          <td className="p-2">
            {place.urlImage ? (
              <img
                className="analysis-img"
                src={place.urlImage}
                alt={place.place}
              />
            ) : (
              "Изображения нет"
            )}
          </td>
          <td className="p-2">{place.admin}</td>
          <td className="p-2">{place.place} ({place.city})</td>
          <td className="p-2">{place.beginDate}</td>
          <td className="p-2">{place.beginTime}</td>
          <td className="p-2">{place.endTime}</td>
          <td className="p-2">
            {place.propertyScooters && "Самокаты"}{" "}
            {place.propertyKeys && "Ключи"} {place.propertyCamera && "Камеры"}{" "}
            {place.propertyTent && "Палатка"}
          </td>
        {access && (
          <td className="p-2">
            {place.location ? place.location : "Локации нет"}
          </td>
        )}
        {access && (
          <td className="p=2">
            <button
              onClick={onActivateEdit}
              type="button"
              className="btn btn-outline-success w-100 scooter-btn"
            >
              Изменить
            </button>
            
              <button
                onClick={() => {
                  dispatch(deletePlace(place._id));
                }}
                type="button"
                className="btn btn-outline-danger rent-btn w-100 mt-2"
              >
                Удалить
              </button>  
            </td>
          )}
        </>
      )}
    </>
  );
};
