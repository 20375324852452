import { rentAPI } from "../api/api";

const GET_RENT = "GET_RENT";
const CREATE_RENT = "CREATE_RENT";
const UPDATE_RENT = "UPDATE_RENT";
const DELETE_RENT = "DELETE_RENT";

//reducer аренды
export const rentReducer = (state = [], action) => {
  switch (action.type) {
    case GET_RENT:
      return action.payload;
    case CREATE_RENT:
      return [...state, action.payload];
    case UPDATE_RENT:
      return state.map((rent) =>
        rent._id === action.payload._id ? action.payload : rent
      );

    case DELETE_RENT:
      return state.filter((rent) => rent._id !== action.payload);
    default:
      return state;
  }
};

//action получения аренд

export const getRent = () => async (dispatch) => {
  try {
    const { data } = await rentAPI.getRent();
    dispatch({ type: GET_RENT, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

//action создания аренды

export const createRent = (rent) => async (dispatch) => {
  try {
    const { data } = await rentAPI.createRent(rent);
    dispatch({ type: CREATE_RENT, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

//action изменения аренды

export const updateRent = (id, rent) => async (dispatch) => {
  try {
    const { data } = await rentAPI.updateRent(id, rent);
    dispatch({ type: UPDATE_RENT, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

//action удаления аренды

export const deleteRent = (id) => async (dispatch) => {
  try {
    await rentAPI.deleteRent(id);
    dispatch({ type: DELETE_RENT, payload: id });
  } catch (error) {
    console.log(error.message);
  }
};
