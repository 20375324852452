import React, { useEffect, useState } from "react";
import axios from "axios";
import { getRent } from "../../store/rentReducer";
import { useDispatch, useSelector } from "react-redux";
import { AnalysisAdmins } from "./AnalysisAdmins";
import { url } from "../../api/api";
import { getPlace, deletePlace, updatePlace} from "../../store/placeReducer";
import { AnalysisPlaces } from "./AnalysisPlaces";
import ReactPaginate from "react-paginate";
import { getPlaceDict } from "../../store/placeDictReducer";


export const Analysis = () => {
  const dispatch = useDispatch();
  //состояние, показывающее можно ли загружать эксель файл со сменами
  const [createState, setCreateState] = useState(false);
  //состояние, показывающее можно ли загружать эксель файл с арендами
  const [createState2, setCreateState2] = useState(false);
  const placeDict = useSelector((state) => state.placeDictReducer);
  const userInfo = useSelector((state) => state.userInfoReducer);
  const [cityList, setCityList] = useState([]);
  const [citySearch, setCitySearch] = useState("");
  const [userSearch, setUserSearch] = useState("");
  const rents = useSelector((state) => state.rentReducer);
  const places = useSelector((state) => state.placeReducer);
  const [filteredPlaces, setFilteredPlaces] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [pageNumber, setpageNumber] = useState(0);

  useEffect(() => {
    dispatch(getRent());
    dispatch(getPlace());
    dispatch(getPlaceDict());
  }, []);

  let cityArray = placeDict.filter(
    (thing, index, self) => index === self.findIndex((t) => t.city === thing.city)
  );
  let cityNames = cityArray.map((item) => item.city);
  useEffect(() => {
    setCityList(cityNames);
  }, [placeDict]);

  useEffect(() =>{
    setFilteredPlaces(places.filter((item) => item.admin.includes(userSearch) && item.city.includes(citySearch)));
  }, [userSearch, citySearch, places]);

  //Логика пагинации страниц с точками
  const placesPerPage = 6;
  const pagesVisited = pageNumber * placesPerPage;
  const pageCount = Math.ceil(filteredPlaces.length / placesPerPage);

  const changePage = ({ selected }) => {
    setpageNumber(selected);
  };

  const createAnalysis = (url) => {
    axios.get(url).catch(function (error) {
      // handle error
      console.log(error);
    });
    setCreateState(true);
  };

  const createAnalysis2 = (url) => {
    axios.get(url).catch(function (error) {
      // handle error
      console.log(error);
    });
    setCreateState2(true);
  };

  // Определяю ответственных убирая повторяющиеся имена из общего массива аренд
  const adminArray = rents.filter(
    (thing, index, self) =>
      index === self.findIndex((t) => t.admin === thing.admin)
  );

  // Оставляю из массива с ответственными только строки с их ФИО
  useEffect(() => {
    let adminArrayNames = adminArray.map((item) => item.admin);
    setAdmins(adminArrayNames);
  }, []);
  let orderedArray = [];
  let sumArray = [];
  // в пустой массив orderedArray закидываю упорядоченные массивы с ответственными и их арендами соответственно
  for (let i = 0; i < admins.length; i++) {
    orderedArray.push(
      rents.filter((rent) =>
        rent.admin.toLowerCase().includes(admins[i].toLowerCase())
      )
    );
  }
  // у каждого массива в orderedArray высчитываю сумму, затем добавляю эти сумму в массив sumArray

  for (let i = 0; i < orderedArray.length; i++) {
    let sum = orderedArray[i].reduce(function (tot, user) {
      // return the sum with previous value
      let userSum = user.tariff * user.price * user.quantity;
      if (user.promoDiscount) {
        let percents = (user.tariff * user.price * user.quantity * 20) / 100;
        userSum = user.tariff * user.price * user.quantity - percents;
      }
      else if (user.quantity >= 10) {
        let percents = (user.tariff * user.price * user.quantity * 15) / 100;
        userSum = user.tariff * user.price * user.quantity - percents;
      }
      else if (user.quantity >= 4) {
        let percents = (user.tariff * user.price * user.quantity * 10) / 100;
        userSum = user.tariff * user.price * user.quantity - percents;
      }
      return tot + userSum;
      // set initial value as 0
    }, 0);
    sumArray.push(sum);
  }
  const finalArray = [];
  //В финальный массив добавляю с помощью цикла админов и их выручку
  for (let i = 0; i < admins.length; i++) {
    finalArray.push({ id: admins[i], admin: admins[i], sum: sumArray[i] });
  }

  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-6">
            <button
              className="btn btn-success btn-sm"
              onClick={() => {
                createAnalysis(`${url}/analysis/places`);
              }}
            >
              Сформировать смены
            </button>
          </div>

          <div className="col-6">
            {createState ? (
              <a
                href={`${url}/static/Точки.xlsx`}
                target="_blank"
                rel="noopener noreferrer"
                download
                className="btn btn-success btn-sm"
              >
                Скачать
              </a>
            ) : (
              <a
                href={`${url}/static/Точки.xlsx`}
                target="_blank"
                rel="noopener noreferrer"
                download
                className="btn btn-success disabled  btn-sm"
                disabled
              >
                Скачать
              </a>
            )}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6">
            <button
              className="btn btn-success btn-sm"
              onClick={() => {
                createAnalysis2(`${url}/analysis/rents`);
              }}
            >
              Сформировать аренды
            </button>
          </div>

          <div className="col-6">
            {createState2 ? (
              <a
                href={`${url}/static/Аренды.xlsx`}
                target="_blank"
                rel="noopener noreferrer"
                download
                className="btn btn-success btn-sm"
              >
                Скачать
              </a>
            ) : (
              <a
                href={`${url}/static/Аренды.xlsx`}
                target="_blank"
                rel="noopener noreferrer"
                download
                className="btn btn-success disabled  btn-sm"
                disabled
              >
                Скачать
              </a>
            )}
          </div>
        </div>
        
        <div className="row">
          <div className="col">
          <label htmlFor="inputCitySearch">Город</label>
          <select
            className="custom-select"
            id="inputCitySearch"
            onChange={(e) => setCitySearch(e.target.value)}
          >
            <option selected value="">Все города</option>
            {cityList.map((city) => ( <option value={city}>{city}</option> ))}
          </select>
          </div>
          <div className="col">
          <label htmlFor="inputUserSearch">Ответственный</label>
          <select
            className="custom-select"
            id="inputUserSearch"
            onChange={(e) => setUserSearch(e.target.value)}
          >
            <option selected value="">Все ответственные</option>
            {admins.map((admin) => ( <option value={admin}>{admin}</option> ))}
          </select>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12 ">
            <h4>Смены</h4>
            <div className="my-3 table-responsive">
              <table
                className="table-bordered table-hover w-100 text-uppercase"
                style={{ minWidth: "600px", cursor: "pointer" }}
              >
                <thead>
                  <tr>
                    <td className="p-2">Фото точки</td>
                    <td className="p-2">Ответственный</td>
                    <td className="p-2">Точка</td>
                    <td className="p-2">Дата</td>
                    <td className="p-2">Начало смены</td>
                    <td className="p-2">Конец смены</td>
                    <td className="p-2">Ответственность</td>
                    <td className="p-2">Локация</td>
                    <td className="p-2">Действия</td>
                  </tr>
                </thead>

                <tbody>
                  {filteredPlaces.length > 0 ? (
                    filteredPlaces
                      .slice(pagesVisited, pagesVisited + placesPerPage)
                      .map((place) => (
                        <tr key={place._id}>
                          <AnalysisPlaces place={place} dispatch={dispatch} deletePlace={deletePlace} updatePlace={updatePlace} access={userInfo.is_super || userInfo.is_admin}/>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td className="p-2">
                        <p className="my-4">Загрузка...</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="mt-4 d-flex justify-content-center align-items-center">
              <ReactPaginate
                marginPagesDisplayed={2}
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>

        <div className="row my-4">
          <div className="col-12 ">
            <h4>Лучшие</h4>
            <div className="my-3 table-responsive">
              <table
                className="table-bordered table-hover w-100 text-uppercase"
                style={{ minWidth: "600px", cursor: "pointer" }}
              >
                <thead>
                  <tr>
                    <td className="p-2">ФИО</td>
                    <td className="p-2">Выручка</td>
                  </tr>
                </thead>

                <tbody>
                  {finalArray.length > 0 ? (
                    finalArray
                      .sort((a, b) => b.sum - a.sum)
                      .map((item) => (
                        <tr key={item.id}>
                          <AnalysisAdmins item={item} />
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td className="p-2">
                        <p className="my-4">Загрузка...</p>
                      </td>
                      <td className="p-2">
                        <p className="my-4">Загрузка...</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
