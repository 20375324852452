import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { authUser } from "../../store/authReducer";
import { getPlaceDict } from "../../store/placeDictReducer";
import { getPlace, createPlace, updatePlace, deletePlace } from "../../store/placeReducer";
import { loadUserInfo, beginShift, endShift, updateUser } from "../../store/userInfoReducer";
import { AnalysisPlaces } from "../Analysis/AnalysisPlaces";
import { registerLocale } from "react-datepicker";
import preloader from "./preloader.gif";
import ru from "date-fns/locale/ru"; // the locale you want
registerLocale("ru", ru);

export const PlaceForm = () => {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.authReducer.user.id);
  const places = useSelector((state) => state.placeReducer);
  const userInfo = useSelector((state) => state.userInfoReducer);
  const placeDict = useSelector((state) => state.placeDictReducer);
  const city = useSelector((state) => state.userInfoReducer.city);
  const admin = useSelector((state) => state.userInfoReducer.fullname);
  const [citySearch, setCitySearch] = useState([]);
  const [placeList, setPlaceList] = useState([]);
  const [placeHist, setPlaceHist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState({});
  const [loading2, setloading2] = useState(false);
  const [imageLoad, setimageLoad] = useState(false);
  const [imageLoaded, setimageLoaded] = useState(false);
  const [activeShift, setActiveShift] = useState(userInfo.is_active);
  const [shiftID, setShiftID] = useState();
  const [pageNumber, setpageNumber] = useState(0);


  //Получаю залогинившего человека, базу и справочник смен
  useEffect(() => {
    dispatch(authUser());
    dispatch(getPlace());
    dispatch(getPlaceDict());
  }, []);
  useEffect(() => {
    dispatch(loadUserInfo(userID));
  }, [userID]);

  //таймер
  const [timestamp, setTime] = useState(new Date());
    useEffect(() => {
      const interval = setInterval(() => setTime(new Date()), 1000);
      return () => {
        clearInterval(interval);
      };
    }, []);
  let dateTimeString = timestamp.toLocaleString("ru-RU");
  let shiftTime = `${timestamp.getHours().toString().padStart(2, '0')}:${timestamp.getMinutes().toString().padStart(2, '0')}`
  const beginDate = timestamp.toLocaleDateString("ru-RU", {timeZone: "UTC"}); 

  //фильтр точек смен и истории смен, привязанных к городу пользователя
  useEffect(() => {
    setCitySearch(placeDict.filter((item) => item.city.includes(city)));
  }, [placeDict, city]);
  let placeNames = citySearch.map((item) => item.place);
  useEffect(() => {
    setPlaceList(placeNames);
  }, [citySearch]);
  useEffect(() => {
    setPlaceHist(places.filter((item) => item.admin.includes(admin)));
  }, [places]);
  useEffect(() => {
    (activeShift && placeHist[0]) ? setShiftID(placeHist[0]._id) : setShiftID("");
  }, [placeHist]);
  
  //Логика пагинации страниц с точками
  const placesPerPage = 6;
  const pagesVisited = pageNumber * placesPerPage;
  const pageCount = Math.ceil(placeHist.length / placesPerPage);
  const changePage = ({ selected }) => {
    setpageNumber(selected);
  };

  //состояние начала/конца смены смены
  const userData = {...userInfo, is_active: !userInfo.is_active}
  const [data, setData] = useState({
    admin: admin,
    city: city,
    place: "",
    beginDate: beginDate,
    beginTime: "",
    endTime: "",
    propertyScooters: false,
    propertyKeys: false,
    propertyCamera: false,
    propertyTent: false,
    urlImage: "",
    location: "",
    timestamp: timestamp,
  });
  useEffect(() => {
    setData({...data, admin: admin, city: city})
  }, [admin, city])
  useEffect(() => {
    (activeShift && placeHist[0]) && (setData(placeHist[0]));
  }, [placeHist])
  useEffect(() => {
    activeShift ? (setData({...data, endTime: shiftTime})) : (setData({...data, beginTime: shiftTime}))
  }, [timestamp])


  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setData({
        ...data,
        location: `${position.coords.latitude}, ${position.coords.longitude}`,
      });
    });
  }, []);

  //Загрузка изображения

  const uploadImage = () => {
    setloading2(true);
    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", "scooter-cloud");
    formData.append("cloud_name", "radius");
    fetch("https://api.cloudinary.com/v1_1/radius/image/upload", {
      method: "post",
      body: formData,
    })
      .then((resp) => resp.json())
      .then((resData) => {
        setData({ ...data, urlImage: resData.url });
      })
      .then(() => setloading2(false))
      .then(() => setimageLoaded(true))
      .catch((err) => console.log(err));
  };

  //Отправка формы

  useEffect(() => {
    if (image.name) {
      setimageLoad(true);
    }
  }, [image]);

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    
    if (!activeShift) {
      dispatch(beginShift);
      dispatch(createPlace(data))
      dispatch(updateUser(userData));
      setActiveShift(true);
   } else {
      dispatch(endShift);
      dispatch(updateUser(userData));
      dispatch(updatePlace(shiftID, data))
      setActiveShift(false);
   }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      <div className="container my-4">
        <div className="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center my-4">
          <Link className="instruction-link font-weight-bold mt-2 mt-lg-0" to="/user">
            {admin
              ? Object.entries(admin).length !== 0
                ? admin
                : "Загрузка..."
              : "Войдите"}
          </Link>
          <Link
            className="instruction-link font-weight-bold mt-2 mt-lg-0"
            to="/instruction"
          >
            Инструкция
          </Link>
          <Link
            className="instruction-link font-weight-bold mt-2 mt-lg-0"
            to="/salary"
          >
            Зарплата
          </Link>
        </div>
        <h4 className="my-4">{`Текущее время: ${dateTimeString}`}</h4>
        <p className="text-danger warning">
          Внимание! Во время сдачи смены убедитесь, что все самокаты в наличии и поставлены на зарядку. В противном случае будет заведен акт.
        </p>

        <div className="row">
          <div className="col-12">
            <form onSubmit={submitHandler}>
              {!activeShift && (
              <div>
                <label htmlFor="inputPlaceSelect">Выберите точку, на которой будете находиться</label>     
                <select
                  className="custom-select"
                  id="inputPlaceSelect"
                  onChange={(e) => setData({...data, place: e.target.value})}
                >
                  <option selected disabled hidden value=""></option>
                  {placeList.map((place) => (<option value={place}>{place}</option>))}
                </select>
              </div>
              )}

              <div className="form-group row mt-4 m-0">
                <div className="col-6 ">
                  <h4 className="mb-4">
                    Начало смены: {data.beginTime ? data.beginTime : "--:--"} 
                  </h4>
                </div>
                <div className="col-6 ">
                  <h4 className="mb-4">
                    Конец смены: {data.endTime ? data.endTime : "--:--"} 
                  </h4>
                </div>
              </div>

              {!activeShift && (<h6 className="my-4">
                Перед началом смены проверьте наличие и исправность: 
              </h6>)}

              {!activeShift && (
              <div className="my-4 d-flex flex-column flex-lg-row justify-content-around">
                <div className="custom-control custom-checkbox form-control-lg">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="propertyScooters"
                    value={data.propertyScooters}
                    onChange={() =>
                      setData({
                        ...data,
                        propertyScooters: !data.propertyScooters,
                      })
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="propertyScooters"
                  >
                    Самокаты
                  </label>
                </div>

                <div className="custom-control custom-checkbox form-control-lg">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="propertyKeys"
                    value={data.propertyKeys}
                    onChange={() =>
                      setData({ ...data, propertyKeys: !data.propertyKeys })
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="propertyKeys"
                  >
                    Ключи
                  </label>
                </div>

                <div className="custom-control custom-checkbox form-control-lg">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="propertyCamera"
                    value={data.propertyCamera}
                    onChange={() =>
                      setData({ ...data, propertyCamera: !data.propertyCamera })
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="propertyCamera"
                  >
                    Камеры
                  </label>
                </div>

                <div className="custom-control custom-checkbox form-control-lg ">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="propertyTent"
                    value={data.propertyTent}
                    onChange={() =>
                      setData({ ...data, propertyTent: !data.propertyTent })
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="propertyTent"
                  >
                    Палатка
                  </label>
                </div>
              </div>
              )}

              {activeShift && (
              <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={(e) => setImage(e.target.files[0])}
                    className="custom-file-input"
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="inputGroupFile04"
                  >
                    {image.name ? image.name : "Выбрать фото"}
                  </label>
                </div>
                <div className="input-group-append">
                  {imageLoad ? (
                    <button
                      className="btn btn-outline-success"
                      type="button"
                      id="inputGroupFileAddon04"
                      onClick={uploadImage}
                    >
                      Загрузить
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-success"
                      type="button"
                      id="inputGroupFileAddon04"
                      disabled
                    >
                      Загрузить
                    </button>
                  )}
                </div>
              </div>
              )}
              {activeShift && (<div className="my-2">
                <p className="font-weight-bold">
                  Загрузите фотографию рабочего места (селфи). На фото должны быть отчетливо видны самокаты, поставленные на зарядку.
                </p>
                <div className="d-flex justify-content-center my-2">
                  {loading2 && <img src={preloader} alt="preloader" />}
                  {data.urlImage && (
                    <img
                      className="place-img"
                      src={data.urlImage}
                      alt={data.urlImage}
                    />
                  )}
                </div>
              </div>
              )}
              
              { (!activeShift) ? (
                data.place != "" && 
                <button
                className="btn btn-success  btn-lg btn-block"
                type="submit"
                >
                  Начать смену
                </button>
              ) : !imageLoaded ? (
                <button
                className="btn btn-success  btn-lg btn-block"
                type="submit"
                disabled
              >
                Фото не загружено
              </button>
              ) : (loading || !shiftID) ?
                 (
                <button
                  className="btn btn-success  btn-lg btn-block"
                  type="submit"
                  disabled
                >
                  Загрузка...
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-success  btn-lg btn-block"
                >
                  Закончить смену
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
      <div className="container my-4">
      <div className="row mt-4">
          <div className="col-12 ">
            <h4>Смены</h4>
            <div className="my-3 table-responsive">
              <table
                className="table-bordered table-hover w-100 text-uppercase"
                style={{ minWidth: "600px", cursor: "pointer" }}
              >
                <thead>
                  <tr>
                    <td className="p-2">Фото точки</td>
                    <td className="p-2">Ответственный</td>
                    <td className="p-2">Точка</td>
                    <td className="p-2">Дата</td>
                    <td className="p-2">Начало смены</td>
                    <td className="p-2">Конец смены</td>
                    <td className="p-2">Исправность</td>
                    {userInfo.is_admin && (<td className="p-2">Локация</td>)}
                    {userInfo.is_admin && (<td className="p-2">Действия</td>)}
                  </tr>
                </thead>

                <tbody>
                  {placeHist.length > 0 ? (
                    placeHist
                      .slice(pagesVisited, pagesVisited + placesPerPage)
                      .map((place) => (
                        <tr key={place._id}>
                          <AnalysisPlaces place={place} dispatch={dispatch} deletePlace={deletePlace} updatePlace={updatePlace} access={userInfo.is_super || userInfo.is_admin}/>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td className="p-2">
                        <p className="my-4">Загрузка...</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="mt-4 d-flex justify-content-center align-items-center">
              <ReactPaginate
                marginPagesDisplayed={2}
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
