import { applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { rentReducer } from "./rentReducer";
import { scooterReducer } from "./scooterReducer";
import { placeReducer } from "./placeReducer";
import { authReducer } from "./authReducer";
import { repairReducer } from "./repairReducer";
import { placeDictReducer } from "./placeDictReducer";
import { partsReducer } from "./partsReducer";
import { scooterRepairReducer } from "./scooterRepairReducer";
import { trackerReducer } from "./trackerRecucer";
import { userInfoReducer } from "./userInfoReducer";
import { tariffDictReducer } from "./tariffDictReducer"
import { penaltiesReducer } from "./penaltiesReducer"
import {employeeReducer} from "./employeeReducer"

//Глобальное состояние
let reducers = combineReducers({
  rentReducer,
  scooterReducer,
  placeReducer,
  authReducer,
  repairReducer,
  placeDictReducer,
  partsReducer,
  scooterRepairReducer,
  trackerReducer,
  userInfoReducer,
  tariffDictReducer,
  penaltiesReducer,
  employeeReducer
});

export let store = createStore(reducers, applyMiddleware(thunkMiddleware));
