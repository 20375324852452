import React, { useEffect, useState } from "react";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import { Register } from "./components/Auth/Register";
import { Signin } from "./components/Auth/Signin";
import { RentForm } from "./components/Rent/RentForm";
import { RentList } from "./components/Rent/RentList";
import { useDispatch, useSelector } from "react-redux";
import { authUser, logout } from "./store/authReducer";
import { loadUserInfo } from "./store/userInfoReducer";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { Notify } from "./components/Notify/Notify";
import { ScooterList } from "./components/ScooterList/ScooterList";
import { PlaceForm } from "./components/Place/PlaceForm";
import { Analysis } from "./components/Analysis/Analysis";
import { Instruction } from "./components/Instruction/Instruction";
import { Salary } from "./components/Salary/Salary";
import { RepairForm } from "./components/Repair/RepairForm";
import { RepairList } from "./components/Repair/RepairList";
import { PartsCatalogue } from "./components/ScooterList/PartsCatalogue";
import { ScooterRepairs } from "./components/ScooterList/ScooterRepairs";
import { EmployeeList } from "./components/Employees/EmployeeList";
import { User } from "./components/User/User";
import { Penalties } from "./components/LocalAdmin/Penalties";
import { getParts } from "./store/partsReducer";
import { getRent } from "./store/rentReducer";
import { getPlace } from "./store/placeReducer";

export const App = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.authReducer.isAuth);
  const userInfo = useSelector((state) => state.authReducer.user);
  const userAccessInfo = useSelector((state) => state.userInfoReducer);
  const [validUser, setValid] = useState(false);
  const [activeShift, setActive] = useState(false);
  const [adminAccess, setAccess] = useState(false);
  const [superAccess, setSuper] = useState(false);

  //Получение основных данных: пользователя и списка городов
  useEffect(() => {
    dispatch(authUser())
  }, []);
  useEffect(() => {
    dispatch(loadUserInfo(userInfo.id));
  }, [userInfo]);
  useEffect(() =>{
    setValid(userAccessInfo.is_valid);
    setAccess(userAccessInfo.is_admin || userAccessInfo.is_super);
    setSuper(userAccessInfo.is_super);
    setActive(userAccessInfo.is_active);
  }, [userAccessInfo])
  

  //Выход из приложения (Разлогинизация)
  const logoutHandle = () => {
    <Redirect to="/signin" />;
    dispatch(logout());
    localStorage.removeItem("token");
  };

  if (!isAuth) {
    <Redirect to="/signin" />;
  } else {
    if (!validUser) {
      <Redirect to="/instruction" />;
    } else if (!activeShift) {
      <Redirect to="/place" />
  }}
  

  //Основная навигация по приложению
  return (
    <>
      <div className="container-fluid px-0">
        <nav className="navbar navbar-expand">
            <ul className="navbar-nav">
              {(activeShift || adminAccess) && (
              <li className="nav-item active">
                {isAuth ? (
                  <Link className="nav-link" to="/">
                    Новый прокат
                  </Link>
                ) : (
                  <Redirect to="/signin" />
                )}
              </li>
              )}

              {(activeShift || adminAccess) && (
              <li className="nav-item">
                {isAuth ? (
                  <Link className="nav-link" to="/rents">
                    Прокаты
                  </Link>
                ) : (
                  <Redirect to="/signin" />
                )}
              </li>
              )}

              {(validUser || adminAccess) && (
              <li className="nav-item">
                {isAuth ? (
                  <Link className="nav-link" to="/place">
                    Смена
                  </Link>
                ) : (
                  <Redirect to="/signin" />
                )}
              </li>
              )}
              
              {adminAccess && (<li className="nav-item">
                {isAuth ? (
                  <Link className="nav-link" to="/scooters">
                    Инвентарь
                  </Link>
                ) : (
                  <Redirect to="/signin" />
                )}
              </li>
              )}
              
              {adminAccess && (<li className="nav-item">
                {isAuth ? (
                  <Link className="nav-link" to="/scooterrepair">
                    Ремонт
                  </Link>
                ) : (
                  <Redirect to="/signin" />
                )}
              </li>
              )}

              {adminAccess && (<li className="nav-item">
                {isAuth ? (
                  <Link className="nav-link" to="/employees">
                    Сотрудники
                  </Link>
                ) : (
                  <Redirect to="/signin"/>
                )}
                </li>
              )}

              {superAccess && (<li className="nav-item">
                {isAuth ? (
                  <Link className="nav-link" to="/penalty">
                    Штрафы
                  </Link>
                ) : (
                  <Redirect to="/signin" />
                )}
              </li>
              )}

              <li className="nav-item">
                {isAuth ? (
                  <Link className="nav-link" to="#" onClick={logoutHandle}>
                    Выйти
                  </Link>
                ) : (
                  <Link className="nav-link" to="/signin">
                    Войти
                  </Link>
                )}
              </li>
            </ul>
        </nav>
        
        {!isAuth ? (<Redirect to="/signin"/>
        ) : !validUser ? (<Redirect to="/instruction"/>
        ) : !activeShift ? (<Redirect to="/place"/>
        ) : <Redirect to="/"/>}

        <Notify />

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. 
            */}

        <Switch>
          {isAuth && <Route exact path="/" render={() => <RentForm />} />}

          {isAuth && <Route exact path="/repair" render={() => <RepairForm />} />}

          {isAuth && <Route exact path="/repairlist" render ={() => <RepairList />} />}

          {isAuth && <Route exact path="/user" render ={() => <User />} />}

          {isAuth && <Route exact path="/parts" render={() => <PartsCatalogue />} />} 

          {isAuth && <Route exact path="/rents" render={() => <RentList />} />}

          {isAuth && <Route exact path="/scooters" render={() => <ScooterList />} />}

          {isAuth && <Route exact path="/scooterrepair" render={() => <ScooterRepairs />} />}

          {isAuth && <Route exact path="/place" render={() => <PlaceForm/>} />}

          {isAuth && <Route exact path="/instruction" render={() => <Instruction />} />}

          {isAuth && <Route exact path="/penalty" render={() => <Penalties />} />}

          {isAuth && <Route exact path="/employees" render={() => <EmployeeList/>} />}

          <Route exact path="/salary" render={() => <Salary />} />

          {isAuth && (
            <Route exact path="/analysis" render={() => <Analysis />} />
          )}

          {!isAuth ? (
            <Route exact path="/signin" render={() => <Signin />} />
          ) : (
            <Redirect to="/" />
          )}

          {!isAuth && (
            <Route exact path="/register" render={() => <Register />} />
          )}
        </Switch>
      </div>
    </>
  );
};
