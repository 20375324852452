import React, {useEffect, useState} from "react";
import { PartsItem } from "./PartsItem";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { useDispatch, useSelector} from "react-redux";
import { getParts } from "../../store/partsReducer";

export const PartsCatalogue = () => {
  const dispatch = useDispatch();
  const parts = useSelector((state) => state.partsReducer);

  useEffect(() =>{
    dispatch(getParts());
  }, []);

  const [pageNumber, setPageNumber] = useState(0);
  const partsPerPage = 8;
  const pagesVisited = pageNumber * partsPerPage;
  const pageCount = Math.ceil(parts.length / partsPerPage);
  const changePage = ({selected}) => {setPageNumber(selected);};

  return (
    <div className="container-fluid my-4">
      <div className = "d-flex align-items-center">
        <h4 className="mr-2 m-0">Запчасти для Ninebot ES</h4>
      </div>
      <div className="row mt-4">
        <div className="col-12 mt-lg-0">
          <div className="my-3 table-responsive">
            <table
              className="table-bordered table-hover w-100 text-uppercase"
              style={{minWidth: "600px", cursor: "pointer"}}
            >
              <thead>
                <tr>
                  <td className="p-2">Артикул</td>
                  <td className="p-2">Изображение</td>
                  <td className="p-2">Наименование</td>
                  <td className="p-2">Тип запчасти</td>
                  <td className="p-2">Совместимые модели</td>
                  <td className="p-2">Цена заказа</td>
                  <td className="p-2">В запасе</td>
                </tr>
              </thead>
              <tbody>
                {parts.length > 0 ? (
                  parts
                    .slice(pagesVisited, pagesVisited + partsPerPage)
                    .map((parts) => (
                      <tr key={parts._id}>
                        <PartsItem
                          {...parts}
                        />
                      </tr>
                    ))
                ) : (
                <tr>
                  <td className="p-2">
                    <p className="my-4">Загрузка...</p>
                  </td>
                  <td className="p-2">
                    <p className="my-4">Загрузка...</p>
                  </td>
                  <td className="p-2">
                    <p className="my-4">Загрузка...</p>
                  </td>
                  <td className="p-2">
                    <p className="my-4">Загрузка...</p>
                  </td>
                  <td className="p-2">
                    <p className="my-4">Загрузка...</p>
                  </td>
                  <td className="p-2">
                    <p className="my-4">Загрузка...</p>
                  </td>
                  <td className="p-2">
                    <p className="my-4">Загрузка...</p>
                  </td>
                </tr>  
                )}
              </tbody>
            </table>
          </div>
          <div className="mt-4 d-flex justify-content-center align-items-center">
            <ReactPaginate
              marginPagesDisplayed={2}
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};