import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { authUser } from "../../store/authReducer";
import { regUserInfo } from "../../store/userInfoReducer";
import { url } from "../../api/api";
import  page1  from "./Page1.png"
import  page2  from "./Page2.png"
import  page3  from "./Page3.png"
import  page4  from "./Page4.png"
import  page5  from "./Page5.png"

export const Instruction = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.authReducer.user);
  const userAccess = useSelector((state) => state.userInfoReducer);
  const [redirectHandler, redirect] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(authUser);
  }, []);

  const [data, setData] = useState({
    userid: userInfo.id,
    username: userInfo.username,
    fullname: `${userInfo.profile.middle_name} ${userInfo.profile.first_name}`,
    city: "",
  })

  useEffect(()=>{
    setData({
      ...data,
      userid: userInfo.id,
      username: userInfo.username,
      fullname: `${userInfo.profile.middle_name} ${userInfo.profile.first_name}`,
    })
  },  [userInfo])

  const registerUser = () => {
    setLoading(true);
    dispatch(regUserInfo(data));
    setTimeout(()=> {setLoading(false); redirect(true)}, 2000)
  }

  return (
    <>
      {redirectHandler && (<Redirect to="user"/>)}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h4 className="my-4">Инструкция по использованию программы прокатов</h4>
            <div className="camera-list">
              <p className="camera-list-item d-flex flex-column">
                <img className="instruction-image my-4" src={page1}/>
                Перед открытием смены, проверьте наличие и исправность самокатов, ключей, камер и палатки/брезента, отметьте галочкой и выберите "Начать Смену"
              </p>
              <p className="camera-list-item d-flex flex-column">
                <img className="instruction-image my-4" src={page2}/>
                После открытия смены можно записывать прокаты. Для начала проката, заполните данные клиента и номера самокатов (Несколько самокатов записывать строго через пробел), и выберите "Начать Прокат"
              </p>
              <p className="camera-list-item d-flex flex-column">
                <img className="instruction-image my-4" src={page3}/>
                Во вкладке "Прокаты" можно посмотреть историю выданных прокатов и проводить завершение текущих прокатов
              </p>
              <p className="camera-list-item d-flex flex-column">
                <img className="instruction-image my-4" src={page4}/>
                Для завершения проката в нужной записи выберите "Завершить поездку" и примите оплату клиента (Наличными или переводом). После приема оплаты клиента выберите "Подтвердить" на дополненной записи
              </p>
              <p className="camera-list-item d-flex flex-column">
                <img className="instruction-image my-4" src={page5}/>
                Для закрытия смены убедитесь, что все самокаты в наличии и на зарядке, загрузите селфи-фотографию рабочего места и выберите "Закончить смену"
              </p>
            </div>
            <div>
            {(!userAccess.is_valid && userAccess.city ==="") ? (
            <button
              onClick={registerUser}
              className="btn btn-success  btn-lg btn-block"
            >
              Регистрация
            </button>
            ) : (!userAccess.is_valid || loading) ? (
              <button
                className="btn btn-success  btn-lg btn-block"
                disabled
              >
                Ожидание доступа
              </button>
            ) : (
              <a
                href={`../place`}
                className="btn btn-success  btn-lg btn-block"
              >
                Вернуться
              </a>
            )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
