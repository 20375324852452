import { scooterApi } from "../api/api";

const GET_SCOOTER = "GET_SCOOTER";
const UPDATE_SCOOTER = "UPDATE_SCOOTER";

//reducer электросамокатов
export const scooterReducer = (state = [], action) => {
  switch (action.type) {
    case GET_SCOOTER:
      return action.payload;
    case UPDATE_SCOOTER:
      return state.map((scooter) => {
        if (scooter._id === action.payload._id) {
          return { ...scooter, scooterError: action.payload.scooterError };
        }
        return scooter;
      });
    default:
      return state;
  }
};

//action получения электросамокатов
export const getScooters = () => async (dispatch) => {
  try {
    const { data } = await scooterApi.getScooter();
    dispatch({ type: GET_SCOOTER, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

//action изменения электросамокатов
export const updateScooter = (id, dataScooter) => async (dispatch) => {
  try {
    const { data } = await scooterApi.updateScooter(id, dataScooter);
    dispatch({ type: UPDATE_SCOOTER, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};
