import React, {useEffect, useState} from "react"
import { useDispatch } from "react-redux"

export const EmployeeItem = ({employee}) => {
  const dispatch = useDispatch()
  const [data, setData] = useState({
    name: employee.fullname,
    is_valid: employee.is_valid,
    is_admin: employee.is_admin,
    is_super: employee.is_super,
    is_active: employee.is_active,
    pass_scan_main: employee.pass_scan_main || null,
    pass_scan_reg: employee.pass_scan_reg || null,
    pass_scan_family: employee.pass_scan_family || null,
    doc_scan_agreement: employee.doc_scan_agreement || null,
    doc_scan_instruction: employee.doc_scan_instruction || null,
    doc_scan_resp: employee.doc_scan_resp || null,
    doc_scan_consent: employee.doc_scan_consent || null
  })

  return (
    <>
      <td className="p-2">{data.name}</td>
      <td className="p-2">{
        !data.is_valid ? "Нет доступа" :
        !data.is_admin ? "Сотрудник" :
        !data.is_super ? "Администратор" :
        "Владелец"
      }</td>
      <td className="p-2">{data.is_active ? "Смена открыта" : "Смена закрыта"}</td>
      <td className="p-2">
        <div className="list-group list-group-flush">
          {data.pass_scan_main && (
            <a href={data.pass_scan_main} target="blank" className="list-group-item list-group-item-action">Паспорт: Главная страница</a>
          )}
          {data.pass_scan_reg && (
            <a href={data.pass_scan_reg} target="blank" className="list-group-item list-group-item-action">Паспорт: Регистрация</a>
          )}
          {data.pass_scan_family && (
            <a href={data.pass_scan_family} target="blank" className="list-group-item list-group-item-action">Паспорт: Семейное положение</a>
          )}
          {data.doc_scan_agreement && (
            <a href={data.doc_scan_agreement} target="blank" className="list-group-item list-group-item-action">Трудовой договор</a>
          )}
          {data.doc_scan_instruction && (
            <a href={data.doc_scan_instruction} target="blank" className="list-group-item list-group-item-action">Должностная инструкция</a>
          )}
          {data.doc_scan_resp && (
            <a href={data.doc_scan_resp} target="blank" className="list-group-item list-group-item-action">Договор об ответственности</a>
          )}
          {data.doc_scan_consent && (
            <a href={data.doc_scan_consent} target="blank" className="list-group-item list-group-item-action">Согласие родителей</a>
          )}
        </div>
      </td>
      <td className="p-2"></td>
    </>
  )
}