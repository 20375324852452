import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { updateScooterRepair } from "../../store/scooterRepairReducer";

export const ScooterRepairItem = ({scooterrepair, updateScooterRepair, dispatch}) => {

const [data, setData] = useState({
  number: scooterrepair.number,
  model: scooterrepair.model,
  malfunction: scooterrepair.malfunction,
  date: scooterrepair.date,
  status: scooterrepair.status,
  parts: scooterrepair.parts,
  sum: scooterrepair.sum,
});

const dateString = new Date(data.date).toLocaleString('ru-RU');

const [editToggle, setEditToggle] = useState(false);

const onActivateEdit = () => {
  setEditToggle(true);
};
const onDeactivateEdit = () => {
  dispatch(updateScooterRepair(scooterrepair._id, data));
  setEditToggle(false);
};

return (
    !editToggle ? (
      <>
      <td className="p-2">{data.number}</td>
      <td className="p-2">{data.model}</td>
      <td className="p-2">{data.malfunction}</td>
      <td className="p-2">{dateString}</td>
      <td className="p-2">{data.status}</td>
      <td className="p-2">{data.parts}</td>
      <td className="p-2">{data.sum}</td>
      <td>
        <button
          onClick={onActivateEdit}
          type="button"
          className="btn btn-outline-success scooter-btn"
        >
          Изменить
        </button>
      </td>
      </>
    ) : (
      <>
      <td className="p-2">{data.number}</td>
      <td className="p-2">{data.model}</td>
      <td className="p-2">
        <input
          autoFocus={true}
          onChange={(e) => setData({...data, malfunction: e.target.value})}
          value={data.malfunction}
          type="text"
        />
      </td>
      <td className="p-2">{dateString}</td>
      <td className="p-2">
        <select
          onChange = {(e) => setData({...data, status: e.target.value})}
          className="custom-select"
          value = {data.status}
        >
          <option value="Диагностика">Диагностика</option>
          <option value="Ожидание запчастей">Ожидание запчастей</option>
          <option value="Ожидание оплаты">Ожидание оплаты</option>
          <option value="В работе">В работе</option>
          <option value="Завершен">Завершен</option>
        </select>
      </td>
      <td className="p-2">
        <input
        autoFocus={true}
        onChange={(e) => setData({...data, parts: e.target.value})}
        value={data.parts}
        type="text"
        />
      </td>
      <td className="p-2"><input
        autoFocus={true}
        onChange={(e) => setData({...data, sum: e.target.value})}
        value={data.sum}
        type="number"
        /></td>
      <td className="p-2">
        <button
          onClick={onDeactivateEdit}
          type="button"
          className="btn btn-outline-success scooter-btn"
        >
          Готово
        </button>
      </td>
      </>
    )
)
}