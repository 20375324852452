import React, {useEffect, useState} from "react"
import { EmployeeItem } from "./EmployeeItem"
import { getEmployees } from "../../store/employeeReducer"
import { useDispatch, useSelector } from "react-redux"

export const EmployeeList = () => {
  const dispatch = useDispatch()
  const employees = useSelector((state) => state.employeeReducer)

  useEffect(() => {
    dispatch(getEmployees())
  }, [])

  return (
    <div className="container-fluid my-4">
      <div className="d-flex align-items-center">
        <h4 className="mr-2">Сотрудники</h4>
      </div>
      <div className="row mt-4">
        <div className="col-12 mt-lg-0">
          <div className="my-3 table-responsive">
            <table
              className="table-bordered table-hover w-100 text-uppercase"
              style={{ minWidth: "600px", cursor: "pointer" }}
            >
              <thead>
                <td className="p-2">Сотрудник</td>
                <td className="p-2">Доступ</td>
                <td className="p-2">Статус</td>
                <td className="p-2">Документы</td>
                <td className="p-2">Действия</td>
              </thead>
              <tbody>
                {employees.length > 0 ? (
                  employees.map((employee) => (
                    <tr key={employee._id}>
                      <EmployeeItem
                        employee={employee}
                      />
                    </tr>
                  ))
                ) : (
                <tr>
                  <td className="p-2">
                    <p className="my-4">Загрузка...</p>
                  </td>
                </tr>
                )

                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}