import { disableTracker, trackerSuccess } from "../../store/trackerRecucer";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const RentItem = ({ rent, updateRent, deleteRent, dispatch, scooterInfo, access, superuser}) => {
  const [data, setData] = useState({
    admin: rent.admin,
    user: rent.user,
    phone: rent.phone,
    serie: rent.serie,
    passnumber: rent.passnumber,
    scooternumber: rent.scooternumber,
    quantity: rent.quantity,
    pledge: rent.pledge,
    date: rent.date,
    rentStart: rent.rentStart,
    rentEnd: rent.rentEnd,
    tariff: rent.tariff,
    price: rent.price,
    payMode: rent.payMode,
    location: rent.location,
    status: rent.status,
    city: rent.city,
    promoDiscount: rent.promoDiscount,
  });

  const [confirm, setConfirm] = useState(false);

  //изменение переключения состояния редактирования
  const [editToggle, setEditToggle] = useState(false);

  //таймер
  const [time, setTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  let rentStartString = (data.rentStart) ? (new Date(data.rentStart).toLocaleString("ru-RU")) : "";
  let rentEndString = (data.rentEnd) ? (new Date(data.rentEnd).toLocaleString("ru-RU")) : "";

  //цена
  const [price, setPrice] = useState(rent.tariff * rent.price);
  //итоговая сумма
  const [total, settotal] = useState(rent.tariff * rent.price * rent.quantity);
  //Логика, где при выборе 4 самокатов или больше скидка будет 10%,
  //при выборе 10 самокатов или больше скидка будет 15%
  //Меньше 4 самокатов скидки нет
  useEffect(() => {
    let oldPrice = data.tariff * rent.price;
    let totalPrice = data.tariff * rent.price * data.quantity;
    if (data.tariff <= 5) {
      settotal(0);
      setPrice(0)
    }
    else if (data.promoDiscount) {
      let percents = (totalPrice * 20) / 100;
      settotal(totalPrice - percents);
      let pricePercents = (oldPrice * 20) / 100;
      setPrice(oldPrice - pricePercents);
    }
    else if (data.quantity >= 10) {
      let percents = (totalPrice * 15) / 100;
      settotal(totalPrice - percents);
      let pricePercents = (oldPrice * 20) / 100;
      setPrice(oldPrice - pricePercents);
    }
    else if (data.quantity >= 4) {
      let percents = (totalPrice * 10) / 100;
      settotal(totalPrice - percents);
      let pricePercents = (oldPrice * 20) / 100;
      setPrice(oldPrice - pricePercents);
    }
    else if (data.quantity < 4) {
      settotal(totalPrice);
      setPrice(oldPrice);
    }
  }, [total, data.tariff, rent.price, data.quantity]);

  const onActivateEdit = () => {
    setEditToggle(true);
  };

  const onDeactivateEdit = () => {
    dispatch(updateRent(rent._id, data));
    setEditToggle(false);
  };

  //Команда завершения активной поездки
  const finishRent = () => {
    let dateTime = time;
    setData({ ...data, 
      rentEnd: dateTime.toISOString(), 
      tariff: Math.floor((Math.abs(dateTime - new Date(data.rentStart)))/60000), 
      status: "Завершена"
    });
    setConfirm(true);
  }

  const confirmFinish = () => {
    let scooters = data.scooternumber.split(/[,. ]/).filter(Boolean);
    for (let scooter of scooters) {
      let currentScooterInfo = scooterInfo.filter((e) => e.scooter.includes(scooter));
      if (currentScooterInfo[0].scooterTracker) {
      dispatch(disableTracker(scooter, {sum: Math.round(price)}));
      }
    };
    dispatch(trackerSuccess(`Прокат завершен, самокаты выключены`))
    dispatch(updateRent(rent._id, data));
    setConfirm(false);
  }

  return (
    <>
      {editToggle ? (
        <>
          <td className="p-2">
            <input
              autoFocus={true}
              onChange={(e) => {
                setData({ ...data, user: e.target.value });
              }}
              value={data.user}
              type="text"
              required
            />
          </td>
          <td className="p-2">
            <input
              autoFocus={true}
              onChange={(e) => {
                setData({ ...data, phone: e.target.value });
              }}
              value={data.phone}
              pattern="(\+?\d[- .]*){11,12}"
              type="tel"
              required
            />
          </td>
          <td className="p-2">
            <input
              autoFocus={true}
              onChange={(e) => {
                setData({ ...data, scooternumber: e.target.value });
              }}
              value={data.scooternumber}
              type="text"
              required
            />
          </td>
          <td className="p-2">
            {" "}
            <input
              autoFocus={true}
              onChange={(e) => {
                setData({ ...data, quantity: e.target.value });
              }}
              value={data.quantity}
              type="number"
              required
            />
          </td>
          <td className="p-2">
            <input
              autoFocus={true}
              onChange={(e) => {
                setData({ ...data, pledge: e.target.value });
              }}
              value={data.pledge}
              type="text"
            />
          </td>
          <td className="p-2">{rentStartString}</td>
          <td className="p-2">{rentEndString}</td>
          <td className="p-2">{data.status}</td>
          <td className="p-2">
            <input
              autoFocus={true}
              onChange={(e) => {
                setData({ ...data, tariff: e.target.value });
              }}
              value={data.tariff}
              type="number"
              required
            />
          </td>
          <td className="p-2">
            <select
              onChange={(e) => setData({...data, payMode: e.target.value})}
              className="custom-select"
              value={data.payMode}
            >
              <option value="Нал">Нал</option>
              <option value="Безнал">Безнал</option>
            </select>
          </td>
          <td className="p-2">{Math.round(total)} р</td>
          <td className="p-2">{rent.admin}</td>
          <td className="p-2">
            <button
              onClick={onDeactivateEdit}
              type="button"
              className="btn btn-outline-success scooter-btn"
            >
              Готово
            </button>
          </td>
        </>
      ) : (
        <>
          <td className="p-2">{data.user}</td>
          <td className="p-2">{data.phone}</td>
          <td className="p-2">{data.scooternumber}</td>
          <td className="p-2">{data.quantity}</td>
          <td className="p-2">{data.pledge}</td>
          <td className="p-2">{rentStartString}</td>
          <td className="p-2">{rentEndString}</td>
          <td className="p-2">{data.status}</td>
          <td className="p-2">{`${data.tariff} минут`}</td>
          <td className="p-2">{data.payMode}</td>
          <td className="p-2">{Math.round(total)} р</td>
          <td className="p-2">{data.admin}</td>
          <td className="p-2">
            {(rent.status === "В поездке" && !confirm) ? (
            <button
              onClick={finishRent}
              type="button"
              className="btn btn-outline-success w-100 scooter-btn"
            >
              Завершить поездку
            </button>
            ) : confirm && (
            <button
              onClick={confirmFinish}
              type="button"
              className="btn btn-outline-success w-100 scooter-btn"
            >
              Подтвердить
            </button>
            )}
            {access && (<button
              onClick={onActivateEdit}
              type="button"
              className="btn btn-outline-success w-100 scooter-btn"
            >
              Изменить
            </button>
            )}
            {superuser && (
              <button
                onClick={() => {
                  dispatch(deleteRent(rent._id));
                }}
                type="button"
                className="btn btn-outline-danger rent-btn w-100 mt-2"
              >
                Удалить
              </button>
            )}
          </td>
        </>
      )}
    </>
  );
};
