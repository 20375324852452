import { scooterRepairApi } from "../api/api";

const GET_SCOOTER_REPAIR = "GET_SCOOTER_REPAIR";
const CREATE_SCOOTER_REPAIR = "CREATE_SCOOTER_REPAIR";
const UPDATE_SCOOTER_REPAIR = "UPDATE_SCOOTER_REPAIR";

export const scooterRepairReducer = (state = [], action) => {
  switch (action.type) {
    case GET_SCOOTER_REPAIR:
      return action.payload;
    case CREATE_SCOOTER_REPAIR:
      return [...state, action.payload];
    case UPDATE_SCOOTER_REPAIR:
      return state.map((scooterrepair) => 
        scooterrepair._id === action.payload._id ? action.payload : scooterrepair
      );
    default: return state;
  }
}

export const getScooterRepair = () => async(dispatch) => {
  try {
    const {data} = await scooterRepairApi.getScooterRepair();
    dispatch({type: GET_SCOOTER_REPAIR, payload: data});
  } catch (error) {
    console.log(error.message);
  }
};

export const createScooterRepair = (scooterrepair) => async(dispatch) => {
  try {
    const {data} = await scooterRepairApi.createScooterRepair(scooterrepair);
    dispatch({type: CREATE_SCOOTER_REPAIR, payload: data});
  } catch (error) {
    console.log(error.message);
  }
};

export const updateScooterRepair = (id, scooterrepair) => async(dispatch) => {
  try {
    const {data} = await scooterRepairApi.updateScooterRepair(id, scooterrepair);
    dispatch({type: UPDATE_SCOOTER_REPAIR, payload: data});
  } catch (error) {
    console.log(error.message);
  }
}