import { userAPI } from "../api/api";

const GET_EMPLOYEES = "GET_EMPLOYEES"

export const employeeReducer = (state = [], action) => {
  switch (action.type) {
    case GET_EMPLOYEES:
      return action.payload
    default:
      return state
  }
}

export const getEmployees = () => async(dispatch) => {
  try {
    const {data} = await userAPI.getEmployees()
    dispatch({type: GET_EMPLOYEES, payload: data})
  } catch (error) {
    console.log(error.message)
  }
}