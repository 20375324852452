import { partsApi } from "../api/api";

const GET_PARTS = "GET_PARTS";

export const partsReducer = (state = [], action) => {
  switch(action.type) {
    case GET_PARTS:
      return action.payload;
    default: return state;
  } 
}

export const getParts = () => async (dispatch) => {
  try {
    const { data } = await partsApi.getParts();
    dispatch({ type: GET_PARTS, payload: data });
  } catch(error) {
    console.log(error.message);
  }
}