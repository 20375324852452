import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPlace } from "../../store/placeReducer";
import { getRent } from "../../store/rentReducer";
import { authUser } from "../../store/authReducer";
import { getPlaceDict } from "../../store/placeDictReducer";
import { getPenalties } from "../../store/penaltiesReducer";

export const Salary = () => {
  const dispatch = useDispatch();
  const places = useSelector((state) => state.placeReducer);
  const rents = useSelector((state) => state.rentReducer);
  const penalties = useSelector((state) => state.penaltiesReducer);
  const adminInfo = useSelector((state) => state.authReducer);
  const placeDict = useSelector((state) => state.placeDictReducer);
  const userInfo = useSelector((state) => state.userInfoReducer);
  const dataAccess = (userInfo.is_admin || userInfo.is_super);
  const [admins, setAdmins] = useState([]);
  const [cities, setCities] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredUsers2, setFilteredUsers2] = useState([]);
  const [filteredDate, setFilteredDate] = useState([]);
  const [filteredDate2, setFilteredDate2] = useState([]);
  const [filteredPenalties, setFilteredPenalties] = useState([]);
  const [search, setSearch] = useState(`${userInfo.fullname}`);
  const [search2, setSearch2] = useState("");
  const [search3, setSearch3] = useState("");
  let cash = "Нал";
  let card = "Безнал";
  const [penaltyResult, setPenaltyResult] = useState(0);
  const [dayResult, setDayResult] = useState(0);
  const [cashResult, setCashResult] = useState(0);
  const [cardResult, setCardResult] = useState(0);
  
  useEffect(() => {
    dispatch(getPlace());
    dispatch(getRent());
    dispatch(getPenalties());
    dispatch(getPlaceDict());
    dispatch(authUser());
  }, []);

  // Определяю ответственных убирая повторяющиеся имена из общего массива аренд
  let adminArray = rents.filter(
    (thing, index, self) =>
      index === self.findIndex((t) => t.admin === thing.admin)
  );
  // Оставляю из массива с ответственными только строки с их ФИО
  let adminNames = adminArray.map((item) => item.admin);
  useEffect(() => {
    setAdmins(adminNames);
  }, [rents]);

  let cityArray = placeDict.filter(
    (thing, index, self) => index === self.findIndex((t) => t.city === thing.city)
  );
  let cityNames = cityArray.map((item) => item.city);
  useEffect(() => {
    setCities(cityNames);
  }, [placeDict]);

  //фильтр пользователей по фамилии
  useEffect(() => {
    setFilteredUsers(
      places.filter((place) =>
        place.admin.toLowerCase().includes(search.toLowerCase()) && place.city.includes(search3)
      )
    );
    setFilteredUsers2(
      rents.filter((rent) =>
        rent.admin.toLowerCase().includes(search.toLowerCase()) && rent.city.includes(search3)
      )
    );
    setFilteredPenalties(
      penalties.filter((penalty) =>
        penalty.subject.toLowerCase().includes(search.toLowerCase())
      )
    )
  }, [search, search3, places, rents, penalties]);

  //фильтр по дате
  useEffect(() => {
    setFilteredDate(
      places.filter((place) => place.beginDate.includes(search2))
    );
    setFilteredDate2(
      rents.filter((rent) =>
        rent.date.includes(search2)
      )
    );
    penalties && setFilteredPenalties(
      penalties.filter((penalty) => {
        new Date(penalty.timestamp).toLocaleDateString("ru-RU").includes(search2)
      }
      )
    )
  }, [search2, places, rents, penalties]);

  //фильтруем пользователя по дате
  const candidate = filteredUsers.filter((x) => filteredDate.includes(x));
  const candidate2 = filteredUsers2.filter((x) => filteredDate2.includes(x));

  //Делаем фильтр где в массивы записываем наличные и безналичные оплаты аренд
  let cashRents = rents.filter((rent) => rent.payMode.includes(cash));
  let cardRents = rents.filter((rent) => rent.payMode.includes(card));

  const cashCandidate = candidate2.filter((x) => cashRents.includes(x));
  const cardCandidate = candidate2.filter((x) => cardRents.includes(x));

  useEffect(() => {
    let sum2 = candidate2.reduce(function (tot, user) {
      // return the sum with previous value
      let userSum = user.tariff * user.price * user.quantity;

      if (user.promoDiscount) {
        let percents = (user.tariff * user.price * user.quantity * 20) / 100;
        userSum = user.tariff * user.price * user.quantity - percents;
      }
      else if (user.quantity >= 10) {
        let percents = (user.tariff * user.price * user.quantity * 15) / 100;
        userSum = user.tariff * user.price * user.quantity - percents;
      }
      else if (user.quantity >= 4) {
        let percents = (user.tariff * user.price * user.quantity * 10) / 100;
        userSum = user.tariff * user.price * user.quantity - percents;
      }

      return tot + Math.round(userSum);

      // set initial value as 0
    }, 0);

    setDayResult(sum2);
  }, [candidate2]);

  useEffect(() => {
    let sum2 = cashCandidate.reduce(function (tot, user) {
      // return the sum with previous value
      let userSum = user.tariff * user.price * user.quantity;

      if (user.quantity >= 10) {
        let percents = (user.tariff * user.price * user.quantity * 15) / 100;
        userSum = user.tariff * user.price * user.quantity - percents;
      }

      if (user.quantity >= 4) {
        let percents = (user.tariff * user.price * user.quantity * 10) / 100;
        userSum = user.tariff * user.price * user.quantity - percents;
      }

      return tot + userSum;

      // set initial value as 0
    }, 0);

    setCashResult(sum2);
  }, [cashCandidate]);

  useEffect(() => {
    let sum2 = cardCandidate.reduce(function (tot, user) {
      // return the sum with previous value
      let userSum = user.tariff * user.price * user.quantity;

      if (user.quantity >= 10) {
        let percents = (user.tariff * user.price * user.quantity * 15) / 100;
        userSum = user.tariff * user.price * user.quantity - percents;
      }

      if (user.quantity >= 4) {
        let percents = (user.tariff * user.price * user.quantity * 10) / 100;
        userSum = user.tariff * user.price * user.quantity - percents;
      }

      return tot + userSum;

      // set initial value as 0
    }, 0);

    setCardResult(sum2);
  }, [cardCandidate]);

  //вся выручка
  const sum = cashResult + cardResult;

  //Создаем массив куда будем скидывать разницу часов в которые работал пользователь
  let paymentHours = [];

  //Конвертация даты в формат для поиска
  function convertDateString(date) {
    let [year, month, day] = date.split("-");
    return `${day}.${month}.${year}`
  }

  //Конвертация часов в минуты
  function convertHourstoMinute(str) {
    let [hours, minutes] = str.split(":");
    return +hours * 60 + +minutes;
  }

  //пушим в массив эти разницы
  for (let i = 0; i < candidate.length; i++) {
    paymentHours.push(
      convertHourstoMinute(candidate[i].endTime) -
        convertHourstoMinute(candidate[i].beginTime)
    );
  }

  //складываем все рабочие минуты
  paymentHours = paymentHours.reduce((a, b) => a + b, 0);

  //получаем 10% с выручки
  const percents = (sum * 10) / 100;

  //оплата работника в минуту составляет 1.04166667 рублей
  const salaryInHours = 1.04166667;

  //Итоговая зарплата работника за отработанные часы
  let sumDay = paymentHours * salaryInHours;

  //функция получения рабочего времени
  function timeConvert(n) {
    let num = n;
    let hours = num / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    return rhours + " часов " + rminutes + " минут.";
  }

  useEffect(() => {
    console.log(filteredPenalties)
    let sum = filteredPenalties.reduce(function (tot, user) {
      return tot + user.sum
    }, 0)
    setPenaltyResult(sum)
  }, [filteredPenalties])

  //Итоговая сумма

  let finalSum = percents + sumDay - penaltyResult;

  return (
    <div className="container">
      <div className="row mt-4">
        <div className="col-12">
          <h2>Калькулятор зарплаты</h2>
        </div>
      </div>
      <div className="row mt-4">
        {dataAccess && (
        <div className="col-12 col-lg-6 form-group">
          <label htmlFor="exampleInputUser">Фамилия ответственного</label>
          <select
            id="exampleInputUser"
            onChange={(e) => setSearch(e.target.value)}
            className="custom-select"
          >
            <option selected disabled hidden value ={userInfo.fullname}>{userInfo.fullname} (Текущий пользователь)</option>
            <option value="">Все</option>
            {admins.map((admin) => (
              <option value={admin}>{admin}</option>
            ))}
          </select>
        </div>
        )}
        <div className="col-12 col-lg-6 form-group">
          <label htmlFor="exampleInputDate">Дата</label>
          <input
            id="exampleInputDate"
            className="form-control"
            type="date"
            onChange={(e) => setSearch2(convertDateString(e.target.value))}
          />
        </div>
        {dataAccess && (
        <div className="col-12 col-lg-6 form-group">
          <label htmlFor="exampleInputCity">Город</label>
          <select
            id="exampleInputCity"
            onChange={(e) => setSearch3(e.target.value)}
            className="custom-select"
          >
            <option selected value="">Все города</option>
            {cities.map((city) => (
              <option value={city}>{city}</option>
            ))}
          </select>
        </div>
        )}
      </div>
      <div className="row ">
        <div className="col-12 col-lg-6 mt-4">
          <h6> Наличные: {Math.ceil(cashResult)} ₽</h6>
        </div>
        <div className="col-12 col-lg-6 mt-4">
          <h6> Безналичные: {Math.ceil(cardResult)} ₽</h6>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-6 mt-4">
          <h6>% c выручки: {percents.toFixed(2)} ₽</h6>
        </div>
        <div className="col-12 col-lg-6 mt-4">
          <h6>Отработано: {timeConvert(paymentHours)}</h6>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6 my-4">
          <h4>Выручка: {dayResult.toFixed(2)} ₽</h4>
        </div>
        <div className="col-12 col-lg-6 my-4">
          <h4>Штраф: {penaltyResult.toFixed(2)} ₽</h4>
        </div>
        <div className="col-12 col-lg-6 mt-4">
          <h4>Зарплата: {finalSum.toFixed(2)} ₽</h4>
        </div>
      </div>
    </div>
  );
};
