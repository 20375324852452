import React, { useState } from "react";
import { Link } from "react-router-dom";
import { registerUser } from "../../store/authReducer";
import { useDispatch } from "react-redux";

export const Register = () => {
  const dispatch = useDispatch();
  const initialState = {
    username: "",
    password: "",
    first_name: "",
    middle_name: "",
    last_name: "",
  };
  const [userData, setUserData] = useState(initialState);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(registerUser(userData));
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <form
              className="mx-auto my-5 pb-5"
              style={{ maxWidth: "500px" }}
              onSubmit={handleSubmit}
            >
              <div className="form-group">
                <div className="form-wrapper">
                  <label htmlFor="first_name">Имя</label>
                  <input
                    placeholder="Введите имя"
                    type="text"
                    className="form-control"
                    id="name"
                    name="first_name"
                    value={userData.first_name}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-wrapper">
                  <label htmlFor="middle_name">Фамилия</label>
                  <input
                    placeholder="Введите фамилию"
                    type="text"
                    className="form-control"
                    id="middle_name"
                    name="middle_name"
                    value={userData.middle_name}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-wrapper">
                  <label htmlFor="last_name">Отчество</label>
                  <input
                    placeholder="Введите отчество"
                    type="text"
                    className="form-control"
                    id="last_name"
                    name="last_name"
                    value={userData.last_name}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-wrapper">
                  <label htmlFor="username">Почта</label>
                  <input
                    placeholder="Введите почту"
                    type="email"
                    className="form-control"
                    id="username"
                    name="username"
                    value={userData.username}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>

              <div className="form-group">
                <div className="form-wrapper">
                  <label htmlFor="password">Пароль</label>
                  <input
                    placeholder="Введите пароль"
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    value={userData.password}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>

              <button type="submit" className="btn btn-success w-100 mt-2">
                Зарегистрироваться
              </button>

              <p className="my-2">
                Уже зарегистрированы? <Link to="/signin">Войти</Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
