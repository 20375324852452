import React, { useEffect, useState } from "react";
import { RentItem } from "./RentItem";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteRent, getRent, updateRent } from "../../store/rentReducer";
import { getPlaceDict } from "../../store/placeDictReducer";
import { getScooters } from "../../store/scooterReducer";

export const RentList = () => {
  const dispatch = useDispatch();
  const scooterInfo = useSelector((state) => state.scooterReducer);
  const rents = useSelector((state) => state.rentReducer);
  const userInfo = useSelector((state) => state.userInfoReducer);
  const [admins, setAdmins] = useState([]);
  const placeDict = useSelector((state) => state.placeDictReducer);
  const trackerObj = useSelector((state) => state.trackerReducer);
  const [cityList, setCityList] = useState([]);
  const [citySearch, setCitySearch] = useState(userInfo.city);
  const [search, setSearch] = useState(userInfo.fullname);
  const [status, setStatus] = useState("");
  const [filteredRents, setFilteredRents] = useState([]);

  useEffect(() => {
    dispatch(getRent());
    dispatch(getPlaceDict());
    dispatch(getScooters());
  }, []);

  let cityArray = placeDict.filter(
    (thing, index, self) => index === self.findIndex((t) => t.city === thing.city)
  );
  let cityNames = cityArray.map((item) => item.city);
  useEffect(() => {
    setCityList(cityNames);
  }, [placeDict]);

  // Составление списка сотрудников
  let adminArray = rents.filter(
    (thing, index, self) =>
      index === self.findIndex((t) => t.admin === thing.admin)
  );
  let adminNames = adminArray.map((item) => item.admin);
  useEffect(() => {
    setAdmins(adminNames);
  }, [rents]);

  //Поиск по админам и городам
  useEffect(() =>{
    setFilteredRents(rents.filter((item) => item.admin.includes(search) && item.city.includes(citySearch) && item.status.includes(status)));
  }, [search, citySearch, rents, status]);

  //таймер
  const [time, setTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  let dateTimeString = time.toLocaleString("ru-RU");

  const [pageNumber, setpageNumber] = useState(0);
  //Логика пагинации страниц с арендами
  const rentsPerPage = 8;
  const pagesVisited = pageNumber * rentsPerPage;
  const pageCount = Math.ceil(filteredRents.length / rentsPerPage);
  const changePage = ({ selected }) => {
    setpageNumber(selected);
  };

  return (
    <div className="container-fluid my-4">
      <div className="d-flex align-items-center">
      <h4 className="mr-2 m-0">Аренды</h4>
      {(userInfo.is_admin || userInfo.is_super) && (<button className="btn btn-success p-0">
        <Link className="nav-link" to="/analysis">
          Анализ
        </Link>
      </button>
      )}
      </div>
      <h4 className="mr-2 m-0">{`Текущее время: ${dateTimeString}`}</h4>
      <div className="d-flex align-items-center">
        <button 
          className="btn btn-success mr-2"
          onClick = {() => setStatus("")}
        >
          Все поездки
        </button>
        <button 
          className="btn btn-success mr-2"
          onClick = {() => setStatus("В поездке")}
        >
          Активные
        </button>
        <button 
          className="btn btn-success mr-2"
          onClick = {() => setStatus("Завершена")}
        >
          Завершенные
        </button>
      </div>
      <div className = "row">
        <div className = "col">
        <label htmlFor="exampleInputUser">Ответственный:</label>
          <select
            id="exampleInputUser"
            onChange={(e) => setSearch(e.target.value)}
            className="custom-select"
          >
            <option selected disabled hidden value={userInfo.fullname}>{userInfo.fullname} (Текущий пользователь)</option>
            <option value="">Все ответственные</option>
            {admins.map((admin) => (
              <option value={admin}>{admin}</option>
            ))}
          </select>
        </div>
      {(userInfo.is_admin || userInfo.is_super) && ( 
        <div className = "col">
        <label htmlFor="exampleInputCity">Город</label>
          <select
            id="exampleInputCity"
            onChange={(e) => setCitySearch(e.target.value)}
            className="custom-select"
          >
            <option selected disabled hidden value={userInfo.city}>{userInfo.city} (Текущий город)</option>
            <option value="">Все города</option>
            {cityList.map((city) => <option value={city}>{city}</option>)}
          </select>
        </div>
        )}
        </div>
            

      <div className="row mt-4">
        <div className="col-12 mt-lg-0">
          <div className="my-3 table-responsive">
            <table
              className="table-bordered table-hover w-100 text-uppercase"
              style={{ minWidth: "600px", cursor: "pointer" }}
            >
              <thead>
                <tr>
                  <td className="p-2">ФИО</td>
                  <td className="p-2">Телефон</td>
                  <td className="p-2">Самокат</td>
                  <td className="p-2">Кол-во</td>
                  <td className="p-2">Залог</td>
                  <td className="p-2">Начало поездки</td>
                  <td className="p-2">Конец поездки</td>
                  <td className="p-2">Статус поездки</td>
                  <td className="p-2">Время поездки</td>
                  <td className="p-2">Способ оплаты</td>
                  <td className="p-2">Сумма</td>
                  <td className="p-2">Ответственный</td>
                  <td className="p-2">Действия</td>
                </tr>
              </thead>

              <tbody>
                {filteredRents.length > 0 ? (
                  filteredRents
                    .slice(pagesVisited, pagesVisited + rentsPerPage)
                    .map((rent) => (
                      <tr key={rent._id}>
                        <RentItem
                          rent={rent}
                          updateRent={updateRent}
                          deleteRent={deleteRent}
                          dispatch={dispatch}
                          trackerObj={trackerObj}
                          scooterInfo={scooterInfo}
                          access={(userInfo.is_admin)} 
                          superuser={(userInfo.is_super)}
                        />
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td className="p-2">
                      <p className="my-4">Загрузка...</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="mt-4 d-flex justify-content-center align-items-center">
            <ReactPaginate
              marginPagesDisplayed={2}
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
