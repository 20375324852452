import React, {useEffect, useState} from "react";


export const PenaltyItem = ({
  penalty,
  dispatch,
  updatePenalty,
  deletePenalty
}) => {
  const [data, setData] = useState({
    admin: penalty.admin,
    subject: penalty.subject,
    timestamp: penalty.timestamp,
    description: penalty.description,
    image: penalty.image,
    sum: penalty.sum,
  })

  const [editToggle, setEditToggle] = useState(false);

  let timestring = new Date(penalty.timestamp).toLocaleString("ru-RU")

  const onActivateEdit = () => {
    setEditToggle(true);
  }

  const onDeactivateEdit = () => {
    dispatch(updatePenalty(penalty._id, data));
    setEditToggle(false);
  }

  return(
    <>
    {editToggle ? (
      <>
        <td className="p-2">{penalty.admin}</td>
        <td className="p-2">{penalty.subject}</td>
        <td className="p-2">
          <input
            onChange={(e) => {
              setData({...data, description: e.target.value})
            }}
            value={data.description}
            type="text"
          />
        </td>
        <td className="p-2">{timestring}</td>
        <td className="p-2">
          {penalty.image ? (
            <img
              className="analysis-img"
              src={penalty.image}
              alt={penalty.description}
            />
          ) : (
            "Изображения нет"
          )}
        </td>
        <td className="p-2">
          <input
            onChange={(e) => {
              setData({...data, sum: e.target.value});
            }}
            value={data.sum}
            type="number"
          />
        </td>
        <td className="p-2">
          <button
            onClick={onDeactivateEdit}
            type="button"
            className="btn btn-outline-success w-100 scooter-btn"
          >
            Готово
          </button>
        </td>
      </>
    ) : (
      <>
        <td className="p-2">{penalty.admin}</td>
        <td className="p-2">{penalty.subject}</td>
        <td className="p-2">{penalty.description}</td>
        <td className="p-2">{timestring}</td>
        <td className="p-2">
          {penalty.image ? (
            <img
              className="analysis-img"
              src={penalty.image}
              alt={penalty.description}
            />
          ) : (
            "Изображения нет"
          )}
        </td>
        <td className="p-2">{penalty.sum}</td>
        <td className="p-2">
          <button
            onClick={onActivateEdit}
            type="button"
            className="btn btn-outline-success w-100 scooter-btn"
          >
            Изменить
          </button>
          <button
           onClick={() => {
             dispatch(deletePenalty(penalty._id))
           }}
           type="button"
           className="btn btn-outline-danger rent-btn w-100 mt-2"
          >
            Удалить
          </button>
        </td>
      </>
    )}
    </>
  )
}