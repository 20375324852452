import React from "react";

export const AnalysisAdmins = ({ item }) => {
  return (
    <>
      <td className="p-2">{item.admin}</td>
      <td className="p-2">{Math.ceil(item.sum)} ₽</td>
    </>
  );
};
