import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";



export const RepairItem = ({repair, updateRepair, deleteRepair, dispatch}) => {

  const [data, setData] = useState({
    admin: repair.admin,
    client: repair.client,
    phone: repair.phone,
    product: repair.product,
    malfunction: repair.malfunction,
    date: repair.date,
    status: repair.status,
    sum: repair.sum,
    payMode: repair.payMode,
    location: repair.location,
    });
        
    //mongodb выводит дату в ISO 8601, форматируем дату на вывод
    const dateOutput = new Date(data.date);
    const dateString = dateOutput.toLocaleString('ru-RU');

    //изменение переключения состояния редактирования
    const [editToggle, setEditToggle] = useState(false);

    //Проверка аутентификации с доступом администратора
    const auth = useSelector((state) => state.authReducer);
    const root = auth.user.username.includes("uramil.radius@gmail.com");
        
    const onActivateEdit = () => {
      setEditToggle(true);
      };
    const onDeactivateEdit = () => {
      dispatch(updateRepair(repair._id, data));
      setEditToggle(false);
      };

    return (
      <>
        {editToggle ? (
          <>
            <td className="p-2">{data.admin}</td>
            <td className="p-2">{data.client}</td>
            <td className="p-2">{data.phone}</td>
            <td className="p-2">{data.product}</td>
            <td className="p-2">{data.malfunction}</td>
            <td className="p-2">{dateString}</td>
            <td className="p-2">
              <select
                onChange={(e) => {setData({...data, status: e.target.value})}}
                className="custom-select"
                value={data.status}
              >
                <option value="Ожидание">Ожидание</option>
                <option value="Принято">Принято</option>
                <option value="Выполнено">Выполнено</option>
                <option value="Оплачено">Оплачено</option>
              </select>
            </td>
            <td className="p-2">
              <select
                onChange={(e) => {setData({...data, payMode: e.target.value})}}
                className="custom-select"
                value={data.payMode}
              >
                <option value="Не опл.">Не оплачено</option>
                <option value="Нал">Наличными</option>
                <option value="Безнал">Безналичный рассчет</option>
              </select>
            </td>
            <td className="p-2">
              <input
                autoFocus={true}
                value={data.sum}
                onChange={(e) => setData({...data, sum: e.target.value})}
                type="number"
              />
            </td>
            <td className="p-2">
              <button
                 onClick={onDeactivateEdit}
                 type="button"
                 className="btn btn-outline-success w-100 scooter-btn"
              >
                Готово
              </button>
            </td>
          </>
        ) : (
          <>
            <td className="p-2">{data.admin}</td>
            <td className="p-2">{data.client}</td>
            <td className="p-2">{data.phone}</td>
            <td className="p-2">{data.product}</td>
            <td className="p-2">{data.malfunction}</td>
            <td className="p-2">{dateString}</td>
            <td className="p-2">{data.status}</td>
            <td className="p-2">{data.payMode}</td>
            <td className="p-2">{data.sum}</td>
            <td className="p-2">
              <button
                onClick={onActivateEdit}
                type="button"
                className="btn btn-outline-success w-100 scooter-btn"
              >
                Принять ремонт
              </button>
              {root && (
                <button
                  onClick={() => {dispatch(deleteRepair(repair._id));}}
                  type="button"
                  className="btn btn-outline-danger rent-btn w-100 mt-2"
                >
                  Удалить
                </button>
              )}
            </td>
          </>
        )}
      </>
    );
};
        