import Toast from "./Toast";
import { useDispatch, useSelector } from "react-redux";

export const Notify = () => {
  const dispatch = useDispatch();
  const notify = useSelector((state) => state.authReducer.notify);

  return (
    <>
      {notify.error && (
        <Toast
          msg={{ msg: notify.error, title: "Ошибка" }}
          handleShow={() => dispatch({ type: "NOTIFY", payload: {} })}
          bgColor="bg-danger"
        />
      )}

      {notify.message && (
        <Toast
          msg={{ msg: notify.message, title: "Успех" }}
          handleShow={() => dispatch({ type: "NOTIFY", payload: {} })}
          bgColor="bg-success"
        />
      )}

    </>
  );
};
