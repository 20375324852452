import { authApi } from "../api/api";

const SET_USER = "SET_USER";
const LOGOUT = "LOGOUT";
const NOTIFY = "NOTIFY";

const initialState = {
  user: {},
  isAuth: false,
  notify: {},
};

//reducer авторизации и регистрации

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload, isAuth: true };
    case LOGOUT:
      return { ...state, user: {}, isAuth: false };
    case NOTIFY:
      return {
        ...state,
        notify: action.payload,
      };
    default:
      return state;
  }
};

//action регистрации

export const registerUser = (data) => async (dispatch) => {
  try {
    await authApi.registerUser(data);
    dispatch({
      type: "NOTIFY",
      payload: { message: "Вы успешно зарегистрировались!" },
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "NOTIFY",
      payload: {
        error: "Не удалось зарегистрироваться!",
      },
    });
  }
};

//action Логинизации

export const loginUser = (data) => async (dispatch) => {
  try {
    const res = await authApi.loginUser(data);

    localStorage.setItem("token", res.data.access_token);

    dispatch({ type: SET_USER, payload: res.data });

    dispatch({ type: "NOTIFY", payload: { message: "Вы успешно вошли!" } });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "NOTIFY",
      payload: {
        error: "Не удалось войти! Проверьте правильность почты или пароля",
      },
    });
  }
};

//action проверки авторизован ли пользователь

export const authUser = () => async (dispatch) => {
  try {
    let token = localStorage.getItem("token");
    const res = await authApi.authUser(token);
    dispatch({ type: SET_USER, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

export const logout = () => ({ type: LOGOUT });
