import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { RepairItem } from "./RepairItem";
import { deleteRepair, getRepairs, updateRepair } from "../../store/repairReducer";

export const RepairList = () => {
    const dispatch = useDispatch();
    const repair = useSelector((state) => state.repairReducer);

    useEffect(() => {
        dispatch(getRepairs());
      }, []);

    const [pageNumber, setpageNumber] = useState(0);
    //Логика пагинации страниц с ремонтами

    const repairPerPage = 8;

    const pagesVisited = pageNumber * repairPerPage;

    const pageCount = Math.ceil(repair.length / repairPerPage);

    const changePage = ({ selected }) => {
        setpageNumber(selected);
  };


    return (
        <div className="container-fluid my-4">
      <div className="d-flex align-items-center">
        <h4 className="mr-2 m-0">Ремонт</h4>
      </div>
      <div className="row mt-4">
        <div className="col-12 mt-lg-0">
          <div className="my-3 table-responsive">
            <table
              className="table-bordered table-hover w-100 text-uppercase"
              style={{ minWidth: "600px", cursor: "pointer" }}
            >
              <thead>
                <tr>
                  <td className="p-2">Админ</td>
                  <td className="p-2">ФИО</td>
                  <td className="p-2">Телефон</td>
                  <td className="p-2">Обьект ремонта</td>
                  <td className="p-2">Неисправность</td>
                  <td className="p-2">Дата</td>
                  <td className="p-2">Статус</td>
                  <td className="p-2">Способ оплаты</td>
                  <td className="p-2">Сумма</td>          
                  <td className="p-2"></td>        
                </tr>
              </thead>

              <tbody>
                {repair.length > 0 ? (
                  repair
                    .slice(pagesVisited, pagesVisited + repairPerPage)
                    .map((repair) => (
                      <tr key={repair._id}>
                        <RepairItem
                          repair={repair}
                          updateRepair={updateRepair}
                          deleteRepair={deleteRepair}
                          dispatch={dispatch}
                        />
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td className="p-2">
                      <p className="my-4">Загрузка...</p>
                    </td>
                    <td className="p-2">
                      <p className="my-4">Загрузка...</p>
                    </td>
                    <td className="p-2">
                      <p className="my-4">Загрузка...</p>
                    </td>
                    <td className="p-2">
                      <p className="my-4">Загрузка...</p>
                    </td>
                    <td className="p-2">
                      <p className="my-4">Загрузка...</p>
                    </td>
                    <td className="p-2">
                      <p className="my-4">Загрузка...</p>
                    </td>
                    <td className="p-2">
                      <p className="my-4">Загрузка...</p>
                    </td>
                    <td className="p-2">
                      <p className="my-4">Загрузка...</p>
                    </td>
                    <td className="p-2">
                      <p className="my-4">Загрузка...</p>
                    </td>
                    <td className="p-2">
                      <p className="my-4">Загрузка...</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            </div>
          <div className="mt-4 d-flex justify-content-center align-items-center">
            <ReactPaginate
              marginPagesDisplayed={2}
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};