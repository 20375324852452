import { repairAPI } from "../api/api";

const GET_REPAIR = "GET_REPAIR";
const CREATE_REPAIR = "CREATE_REPAIR";
const UPDATE_REPAIR = "UPDATE_REPAIR";
const DELETE_REPAIR = "DELETE_REPAIR";

//reducer ремонтов
export const repairReducer = (state = [], action) => {
    switch(action.type) {
        case GET_REPAIR:
            return action.payload;
        case CREATE_REPAIR:
            return [...state, action.payload];
        case UPDATE_REPAIR:
            return state.map((repair) =>
            repair._id === action.payload._id ? action.payload : repair
            );
        case DELETE_REPAIR:
            return state.filter((repair) => repair._id !== action.payload);
        default:
            return state; 
    }
}

//action списка ремонтов

export const getRepairs = () => async (dispatch) => {
    try {
        const { data } = await repairAPI.getRepair();
        dispatch({ type: GET_REPAIR, payload: data });
    } catch (error) {
        console.log(error.message);
    }
};

//action создания ремонта

export const createRepair = (repair) => async (dispatch) => {
    try {
        const { data } = await repairAPI.createRepair(repair);
        dispatch({ type: CREATE_REPAIR, payload: data });
    } catch (error) {
        console.log(error.message);
    }
};

//action изменения ремонта

export const updateRepair = (id, repair) => async (dispatch) => {
    try {
        const { data } = await repairAPI.updateRepair(id, repair);
        dispatch({ type: UPDATE_REPAIR, payload: data });
    } catch (error) {
        console.log(error.message);
    }
};

//action удаления ремонта

export const deleteRepair = (id) => async (dispatch) => {
    try {
        await repairAPI.deleteRepair(id);
        dispatch({ type: DELETE_REPAIR, payload: id });
    } catch (error) {
        console.log(error.message);
    }
}