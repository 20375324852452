import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { authUser, loginUser } from "../../store/authReducer";

export const Signin = () => {
  const dispatch = useDispatch();
  const initialState = {
    username: "",
    password: "",
    grant_type: "password",
    client_id: 2,
    client_secret: "WJC4eSpLDGtdtcyh0NfuwCNk6PtCZH1U9vNh0Hu7",
  };
  const [userData, setUserData] = useState(initialState);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(loginUser(userData));
    dispatch(authUser());
  };

  // useEffect(() => {
  //   if (Object.keys(auth).length !== 0) router.push("/");
  // }, [auth]);

  return (
    <>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <form
                className="mx-auto my-5 pb-5"
                style={{ maxWidth: "500px" }}
                onSubmit={handleSubmit}
              >
                <div className="form-group">
                  <div className="form-wrapper">
                    <label htmlFor="username">Почта</label>
                    <input
                      placeholder="Введите почту"
                      type="email"
                      className="form-control"
                      id="username"
                      name="username"
                      value={userData.username}
                      onChange={handleChangeInput}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="form-wrapper">
                    <label htmlFor="password">Пароль</label>
                    <input
                      placeholder="Введите пароль"
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      value={userData.password}
                      onChange={handleChangeInput}
                    />
                  </div>
                </div>

                <button type="submit" className="btn btn-success w-100 mt-2">
                  Войти
                </button>

                <p className="my-2">
                  Не зарегистрированы?{" "}
                  <Link to="/register">Зарегистрироваться</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
