import React, { useEffect, useState } from "react";
import { ScooterItem } from "./ScooterItem";
import { useDispatch, useSelector } from "react-redux";
import { getScooters, updateScooter } from "../../store/scooterReducer";
import { Link } from "react-router-dom";

export const ScooterList = () => {
  const [owner, setOwner] = useState("Radius");
  const [type, setType] = useState("Самокат");
  const scooters = useSelector((state) => state.scooterReducer);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [model, setModel] =useState("");
  const [filteredScooters, setfilteredScooters] = useState([]);
  //Состояние с рабочими электросамокатами
  const [workedScooters, setWorkedScooters] = useState([]);
  //Состояние со сломанными электросамокатами
  const [errorScooters, setErrorScooters] = useState([]);
  const [models, setModels] = useState([])
  const [owners, setOwners] = useState([])
  const [types, setTypes] = useState([])

  useEffect(() => {
    dispatch(getScooters());
  }, []);

  useEffect(() => {
    setOwners([... new Set(scooters.map(item => (item.owner)))].filter(item => item !== undefined))
    setTypes([... new Set(scooters.map(item => (item.type)))].filter(item => item !== undefined))
  }, [scooters])

  useEffect(() => {
    setModels([... new Set(scooters.map(item => (item.type.includes(type) && item.model)))].filter(item => item !== undefined))
    setModel("")
  }, [scooters, type])

  //динамический поиск электросамокатов
  useEffect(() => {
    setfilteredScooters(
      scooters.filter((item) => item.scooter.includes(search) && item.scooterPlace.toLowerCase().includes(search2.toLowerCase()) && item.model.includes(model) && item.owner === owner && item.type === type)
  )}, [search, search2, scooters, owner, type, model]);

  //фильтрация рабочих и сломанных электросамокатов
  useEffect(() => {
    setWorkedScooters(filteredScooters.filter((item) => !item.scooterError));
    setErrorScooters(filteredScooters.filter((item) => item.scooterError));
  }, [filteredScooters]);

  const showAllScooters = () => {
    setfilteredScooters(scooters.filter((item) => item));
  };

  const showWorkedScooters = () => {
    setfilteredScooters(scooters.filter((item) => !item.scooterError));
  };

  const showErrorScooters = () => {
    setfilteredScooters(scooters.filter((item) => item.scooterError));
  };

  return (
    <div className="App">
      <div className="container-fluid my-4">
        <div className="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center my-4">
          <h4 className="my-4">Инвентарь</h4>
          <Link
            className="instruction-link font-weight-bold mt-2 mt-lg-0"
            to="/scooterrepair"
          >
            Ремонт самокатов
          </Link>
          <Link
            className="instruction-link font-weight-bold mt-2 mt-lg-0"
            to="/parts"
          >
            Запчасти
          </Link>
        </div>
        <div className="row my-2">
          <div className="col-12 col-lg-4">
            Рабочие: {workedScooters.length} шт.
          </div>
          <div className="col-12 col-lg-4">
            Сломанные: {errorScooters.length} шт.
          </div>
          <div className="col-12 col-lg-4">Всего: {filteredScooters.length} шт.</div>
        </div>
        <div className="row my-4">
          <div className="col-12">
            <button
              className="btn btn-success mr-2"
              onClick={() => {
                showAllScooters();
              }}
            >
              Все
            </button>
            <button
              className="btn btn-success mr-2"
              onClick={() => {
                showWorkedScooters();
              }}
            >
              Рабочие
            </button>
            <button
              className="btn btn-success"
              onClick={() => {
                showErrorScooters();
              }}
            >
              Нерабочие
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder="Введите место"
              onChange={(e) => setSearch2(e.target.value)}
            />
          </div>
          <div className="col">
            <select className="custom-select"
              onChange={(e) => setType(e.target.value)}
            >
              <option selected disabled hidden>Самокат</option>
              {types.map(type => 
                <option value={type}>{type}</option>  
              )}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder="Введите номер самоката"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="col">
            <select className="custom-select" defaultValue="Самокат"
              onChange={(e) => setOwner(e.target.value)}
            >
              <option selected disabled hidden>Radius</option>
              {owners.map(owner => 
                <option value={owner}>{owner}</option>  
              )}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
          <select className="custom-select" defaultValue=""
            onChange={(e) => setModel(e.target.value)}
          >
            <option value="" selected>Все модели</option>
            {models.map(model => 
              <option value={model}>{model}</option>  
            )}
          </select>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-lg-0">
            <div className="my-3 table-responsive">
              <table
                className="table-bordered table-hover w-100 text-uppercase"
                style={{ minWidth: "600px", cursor: "pointer" }}
              >
                <thead>
                  <tr>
                    <td className="p-2">Фото</td>
                    <td className="p-2">Номер</td>
                    <td className="p-2">Модель</td>
                    <td className="p-2">Состояние</td>
                    <td className="p-2">Местоположение</td>
                    <td className="p-2">Активность</td>
                    <td className="p-2">Примечания</td>
                    <td className="p-2">Запчасти</td>
                    <td className="p-2">Работы</td>
                    <td className="p-2">Действия</td>
                  </tr>
                </thead>

                <tbody>
                  {filteredScooters.length > 0 ? (
                    filteredScooters.map((scooter) => {
                      return (
                        <tr key={scooter._id}>
                          <ScooterItem
                            {...scooter}
                            updateScooter={updateScooter}
                          />
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="p-2">
                        <p className="my-4">Загрузка...</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
