import React, {useEffect, useState} from "react";
import {ScooterRepairItem} from "./ScooterRepairItem";
import { useDispatch, useSelector } from "react-redux";
import { getScooterRepair, createScooterRepair, updateScooterRepair } from "../../store/scooterRepairReducer";
import { Link } from "react-router-dom";

export const ScooterRepairs = () => {
  const scooterrepairs = useSelector((state) => state.scooterRepairReducer);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getScooterRepair());
  }, [])

  const [time, setTime] = useState(new Date);
  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => {
      clearInterval(interval);
    }
  }, []);

  const [data, setData] = useState({
    number: "",
    model: "",
    malfunction: "",
    date: time,
    status: "Диагностика",
    parts: "",
    sum: 0   
  });

  useEffect(() => {
    setData({
      ...data,
      date: time,
    })
  }, [time]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(createScooterRepair(data));

    setData({
      number: "",
      model: "",
      malfunction: "",
      date: time,
      status: "Диагностика",
      parts: "",
      sum: 0   
    });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return(
    <>
      <div className="container my-4">
        <div className = "d-flex align-items-center">
          <h4 className="mr-2 m-0">Оформить неисправность самоката</h4>
          <Link
          className="instruction-link font-weight-bold mt-2 mt-lg-0"
          to="/parts"
          >
          Каталог запчастей
          </Link>
        </div>
        <form onSubmit={submitHandler}> 
          <div>
            <div className="form-group">
              <label htmlFor="inputNumber">Номер самоката</label>
              <input
                type="text"
                className="form-control"
                id="inputNumber"
                placeholder="Например, 30056"
                value={data.number}
                name="number"
                onChange={(e) => setData({...data, number: e.target.value})}
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputModel">Модель самоката</label>
              <input
                type="text"
                className="form-control"
                id="inputModel"
                placeholder="Например, Ninebot ES1"
                value={data.model}
                name="model"
                onChange={(e) => setData({...data, model: e.target.value})}
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputMalfunction">Описание неисправности</label>
              <input
                type="text"
                className="form-control"
                placeholder="Описание сломанной детали или код ошибки при включении самоката."
                value={data.malfunction}
                name="malfunction"
                onChange={(e) => setData({...data, malfunction: e.target.value})}
              />
            </div>
            {loading ? (
              <button
                className="btn btn-success btn-lg btn-block"
                type="submit"
                disabled
              >
                Загрузка...
              </button>
            ) : (
              <button
                className="btn btn-success btn-lg btn-block"
                type="submit"
              >
                Создать заявку
              </button>
            )}
          </div>
        </form>
        </div>
        
        <div className="container my-4">
        <h4 className="mr-2 m-0">Журнал регистрации неисправностей</h4>
            <table
              className="table-bordered table-hover w-100 text-uppercase"
              style={{minWidth: "600px", cursor: "pointer"}}
            >
              <thead>
                <tr>
                  <td className="p-2">Номер</td>
                  <td className="p-2">Модель</td>
                  <td className="p-2">Неисправность</td>
                  <td className="p-2">Дата записи</td>
                  <td className="p-2">Статус</td>
                  <td className="p-2">Требуемые запчасти</td>
                  <td className="p-2">Общая сумма ремонта</td>
                  <td className="p-2">Действия</td>
                </tr>
              </thead>
              <tbody>
                {scooterrepairs.length > 0 ? (
                  scooterrepairs.map((scooterrepair) => {
                    return (
                      <tr key={scooterrepair._id}>
                        <ScooterRepairItem
                          scooterrepair={scooterrepair}
                          updateScooterRepair={updateScooterRepair}
                          dispatch={dispatch}
                        />
                      </tr>
                    );
                  })
                ) : (
                  <tr><td>Загрузка...</td></tr>
                )}
              </tbody>
            </table>
      </div>
    </>
  );
} 