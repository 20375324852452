import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

export const PartsItem = ({
  _id,
  article,
  name,
  models,
  price,
  link,
  img,
  type,
  stock,
}) => {
  return(
    <>
      <td className="p-2">{article}</td>
      <td className="p-2">
        {img ? (
          <img 
            className="part-img" 
            src={img} 
            alt={name} 
          />
        ) : ( "Изображения нет" )}
      </td>
      <td className="p-2">
        <Link to={link}>{name}</Link>
      </td>
      <td className="p-2">{type}</td>
      <td className="p-2">{models}</td>
      <td className="p-2">{price} Р</td>
      <td className="p-2">{stock} шт.</td>
    </>
  );
};