import { userAPI } from "../api/api";

const UPDATE_USER_INFO = "UPDATE_USER_INFO";
const START_SHIFT = "START_SHIFT";
const END_SHIFT = "END_SHIFT";

const initialState = {
  userid: "",
  username: "",
  fullname: "",
  city: "",
  is_valid: false,
  is_admin: false,
  is_super: false,
  is_active: false,
  pass_scan_main: "",
  pass_scan_reg: "",
  pass_scan_family: "",
  doc_scan_agreement: "",
  doc_scan_instruction: "",
  doc_scan_resp: "",
  doc_scan_consent: ""
}

export const userInfoReducer = (state = initialState, action) => {
  switch(action.type) {
    case UPDATE_USER_INFO:
      return action.payload;
    case START_SHIFT:
      return {...state, is_active: true};
    case END_SHIFT:
      return {...state, is_active: false};
    default:
      return state;
  }
}

export const loadUserInfo = (userid) => async(dispatch) => {
  try {
    const {data} = await userAPI.getUser(userid);
    if (data!= null) dispatch({type: UPDATE_USER_INFO, payload: data});
  } catch (error) {
    console.log(error.message);
  }
}

export const regUserInfo = (userdata) => async(dispatch) => {
  try {
    const {data} = await userAPI.newUser(userdata);
    dispatch({type: UPDATE_USER_INFO, payload: data});
  } catch (error) {
    console.log(error.message);
  }
}

export const updateUser = (userdata) => async(dispatch) => {
  try {
    const {data} = await userAPI.updateUser(userdata);
    dispatch({type: UPDATE_USER_INFO, payload: data});
  } catch (error) {
    console.log(error);
  }
}

export const beginShift = () => ({type: START_SHIFT})
export const endShift = () => ({type: END_SHIFT})